import { pad } from "./padding";

// export const NUM_ROWS = 16
//
// export const WIDTH = (NUM_ROWS + 2) * 36
// export const HEIGHT = NUM_ROWS * 36 + 50;
export const ballRadius = 6;
export const obstacleRadius = 4;

export const gravity = pad(0.25);
export const horizontalFriction = 0.4;
export const verticalFriction = 0.7;

export const sinkWidth = 36;
// export const NUM_SINKS = NUM_ROWS + 1;
