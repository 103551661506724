import { FC, useContext, useEffect } from 'react'
import s from './Control.module.css'
import Button from '../../Button/Button'
import Input from '../../Input/Input'
import Checkbox from '../../Checkbox/Checkbox'
import InitialStateContext from '../../../context/InitialStateContext'
import { checkCurrencyCode } from '../../../utils/checkCurrencyCode'
import Select from '../../Select/Select'
import { BallCount, RiskType, RowsType } from '../../../types/Game'
import { makeEnum } from '../../../utils/makeEnum'
import { InitialState } from '../../../types/Messages'
import {Translations, useTranslation} from "../../../hooks/useTranslation";

const Control: FC<IProps> = ({
     amount,
     setAmount,
     rows,
     setRows,
     balls,
     setBalls,
     risk,
     setRisk,
     changeResult,
     isAutobet,
     setIsAutobet,
     numberOfRounds,
     setNumberOfRounds,
     spin,
     runAutobet,
     isAutobetRunning,
     isFetching,
     windowBallsCount,
}) => {
    const { t } = useTranslation()
    const [initialState] = useContext(InitialStateContext)

    const changeValue = (
        name: 'amount' | 'rows' | 'balls' | 'risk' | 'numberOfRounds',
        prevValue: string,
        newValue: string | ((prev: string) => string)
    ) => {
        if (typeof newValue !== 'string') {
            newValue = newValue(prevValue)
        }

        switch (name) {
            case 'amount' :
                setAmount(newValue)
                localStorage.setItem(process.env.REACT_APP_AMOUNT_KEY as string, newValue)
                break

            case 'rows' :
                setRows(+newValue)
                localStorage.setItem(process.env.REACT_APP_ROWS_KEY as string, newValue)
                changeResult(+newValue, balls, risk, initialState)
                break

            case 'balls' :
                setBalls(+newValue)
                localStorage.setItem(process.env.REACT_APP_BALLS_KEY as string, newValue)
                changeResult(rows, +newValue, risk, initialState)
                break

            case 'risk' :
                setRisk(newValue as RiskType)
                localStorage.setItem(process.env.REACT_APP_RISK_KEY as string, newValue)
                changeResult(rows, balls, newValue as RiskType, initialState)
                break

            case 'numberOfRounds':
                setNumberOfRounds(newValue)
                break
        }
    }

    /* Bet amount */
    const divideAmountValidation = (amount: string): string => {
        if (+amount / 2 >= initialState?.limits?.minBet) {
            return (+amount / 2).toFixed(initialState?.currencyPrecision).toString()
        }

        return amount
    }

    const multiplyAmountValidation = (amount: string): string => {
        if (+amount * 2 <= initialState?.limits?.maxBet) {
            return (+amount * 2).toFixed(initialState?.currencyPrecision).toString()
        }

        return amount
    }

    const amountActions = [
        { text: '½', action: () => changeValue('amount', amount, divideAmountValidation(amount)) },
        { text: '2×', action: () => changeValue('amount', amount, multiplyAmountValidation(amount)) },
        { text: t('MAX'), action: () => changeValue(
                'amount',
                amount,
                initialState?.limits?.maxBet.toFixed(initialState?.currencyPrecision),
        )},
    ]
    /* ----------- */

    /* Number Of Rounds */
    const numberOfRoundsActions = [
        { text: '20', action: () => setNumberOfRounds('20') },
        { text: '50', action: () => setNumberOfRounds('50') },
        { text: '100', action: () => setNumberOfRounds('100') },
    ]
    /* ----------- */

    useEffect(() => {
        if (!initialState) return

        const amount = localStorage.getItem(process.env.REACT_APP_AMOUNT_KEY as string)

        setAmount(
            amount ?? initialState?.limits?.minBet
                .toFixed(initialState?.currencyPrecision)
                .toString()
        )
    }, [initialState])

    return (
        <div className={s.control}>
            {!isAutobet && (
                <Button
                    onClick={() => spin(+amount, rows, balls, risk)}
                    disabled={!Object.keys(initialState).length || isFetching || (!!windowBallsCount && balls > 1)}
                    clickSoundExcept
                >
                    {t('BET')}
                </Button>
            )}
            {isAutobet && (
                <Button
                    onClick={runAutobet}
                    disabled={isAutobetRunning || !!windowBallsCount}
                    clickSoundExcept
                >
                    {t('STARTAUTOBET')}
                </Button>
            )}

            <Input
                value={amount}
                onChange={value =>
                    changeValue('amount', amount, value)
                }
                label={t('BETAMOUNT')}
                actions={amountActions}
                currency={checkCurrencyCode(initialState?.currencyCode)}
                min={initialState?.limits?.minBet}
                max={initialState?.limits?.maxBet}
                precision={initialState?.currencyPrecision}
                autoReplace={[
                    [/[^.\d]+/g, ""],
                    [/^([^\.]*\.)|\./g, '$1'],
                ]}
                readOnly={isAutobetRunning}
                disabled={!!windowBallsCount}
            />

            <div className={s.grid}>
                <Select
                    label={t('ROWS')}
                    value={rows.toString()}
                    onChange={value =>
                        changeValue('rows', rows.toString(), value)
                    }
                    disabled={!!windowBallsCount}
                >
                    {makeEnum(RowsType).map(key => (
                        <option key={key} value={RowsType[key as unknown as number]}>
                            {key.replace('_', '')}
                        </option>
                    ))}
                </Select>
                <Select
                    label={t('BALLS')}
                    value={balls.toString()}
                    onChange={value =>
                        changeValue('balls', balls.toString(), value)
                    }
                    disabled={!!windowBallsCount}
                >
                    {makeEnum(BallCount).map(key => (
                        <option key={key} value={BallCount[key as unknown as number]}>
                            {key.replace('_', '')}
                        </option>
                    ))}
                </Select>
            </div>

            <Select
                label={t('RISK')}
                value={risk}
                onChange={value => changeValue('risk', risk, value)}
                disabled={!!windowBallsCount}
            >
                {makeEnum(RiskType).map(key => (
                    <option key={key} value={RiskType[key as unknown as RiskType]}>
                        {t(key.toUpperCase() as keyof Translations)}
                    </option>
                ))}
            </Select>

            <div className={s.autobet}>
                <Input
                    value={numberOfRounds}
                    onChange={value =>
                        changeValue('numberOfRounds', numberOfRounds, value)
                    }
                    label={t('NUMROUNDS')}
                    actions={numberOfRoundsActions}
                    max={10000}
                    autoReplace={[[/[^\d]/ig, '']]}
                    readOnly={isAutobet}
                />
                <Checkbox
                    checked={isAutobet}
                    onChange={setIsAutobet}
                    className={s.checkbox}
                    clickSoundExcept
                >
                    {t('AUTOBET')}
                </Checkbox>
            </div>
        </div>
    )
}

export default Control

interface IProps {
    amount: string
    setAmount: (value: ((prev: string) => string) | string) => void
    rows: RowsType
    setRows: (value: ((prev: RowsType) => RowsType) | RowsType) => void
    balls: BallCount
    setBalls: (value: ((prev: BallCount) => BallCount) | BallCount) => void
    risk: RiskType
    setRisk: (value: ((prev: RiskType) => RiskType) | RiskType) => void
    changeResult: (rows: RowsType, balls: BallCount, risk: RiskType, initialState: InitialState) => void
    isAutobet: boolean
    setIsAutobet: (value: boolean) => void
    numberOfRounds: string
    setNumberOfRounds: (value: ((prev: string) => string) | string) => void
    spin: (amount: number, rows: RowsType, balls: BallCount, risk: RiskType) => void
    runAutobet: () => void
    isAutobetRunning: boolean
    isFetching: boolean
    windowBallsCount: number
}
