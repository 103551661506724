import { createContext } from 'react'
import { IOldHashAndKey } from '../types/Messages'

const OldHashAndKeyContext = createContext<InitialStateContextType>([
    null,
    () => {}
])

export default OldHashAndKeyContext

type InitialStateContextType = [
    IOldHashAndKey | null,
    (value: ((prev: IOldHashAndKey | null) => IOldHashAndKey | null) | IOldHashAndKey | null) => void
]
