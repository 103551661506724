import { RowsType } from '../types/Game'

export const outcomes: Record<RowsType, Record<number, number[]>> = {
    [RowsType._8]: {
        0: [1664521.9015145032, 1699213.2698742447, 1661398.860561635, 1807635.2931842676, 1816596.9759985665, 1817819.53356918, 1846023.160018453, 1662025.761542645],
        1: [1669879.9097909685, 1758278.4934621765, 1669242.891328408, 1698010.6358855355, 1768512.089081175, 1731857.6845737887, 1678794.6628332522, 1660709.8336247993, 1651791.5490198238, 1669659.4146149594, 1723921.6200961666, 1810192.9271207505, 1681955.975552152, 1780500.3307845218, 1825813.7482279383, 1828397.2866878759, 1678422.7193245632, 1651950.4157239601, 1701791.7401511846, 1783438.765152818, 1821863.3712175204, 1775264.451023879, 1812625.53157353],
        2: [1780636.270926479, 1724522.872425366, 1656089.0400086793, 1664408.5026034068, 1773631.893096626, 1827749.8359511173, 1730018.4770634852, 1764137.0271417655, 1821086.4427186584, 1679774.6020014319, 1843907.9098393193, 1679545.4058146928, 1654938.8788129839, 1714934.4328115073, 1731809.2282629982, 1746544.6550211066, 1683933.6224768471, 1770802.9041524236, 1815354.1114802696, 1830577.6288687612, 1828576.840139533, 1758308.6321188714, 1651816.6576429242, 1708479.9428367317, 1740178.926176311, 1654667.4999619143, 1670951.2692800215, 1812920.354120388, 1696191.274460412, 1776765.1921810245, 1829279.1134896243, 1808184.3625150474, 1826800.1997898614, 1680591.349270903, 1665571.5959349559, 1817932.0187045203, 1679350.0488652592, 1670765.703885306, 1804011.384330542, 1812435.6590240628, 1833581.8072708354, 1819478.601296552, 1816365.141611809, 1816155.9498000557, 1842913.7024012306, 1729648.9989508968, 1740264.0441677775, 1693446.5906087668, 1654414.9225102158, 1747464.1497409053, 1776724.4386127673, 1661606.1553678494, 1665065.5506972922, 1658550.3907339438, 1755618.386757859, 1727393.5432645697, 1678256.1341300365, 1677075.7849041093, 1773199.417797355, 1731580.556446539, 1747459.6189012248, 1740332.962085319, 1781184.419144503, 1827579.9190478171, 1761293.7075431421, 1821550.880933295, 1673220.1442696557, 1652323.2388274288, 1807247.048440538, 1662365.1616780646, 1664341.7704501986, 1804957.9099468791, 1827377.4543530638, 1654547.1623367798, 1814818.8217097337, 1822045.0462627271],
        3: [1824128.287018599, 1689702.0943409444, 1694887.5215314953, 1695492.7735006313, 1722687.6967845794, 1740708.3846027837, 1754192.1715149542, 1775169.8178338585, 1827925.4844018696, 1800667.001415357, 1777509.4210698819, 1724768.653238301, 1811497.2472703955, 1674248.1780167895, 1667262.6031534495, 1655650.109175404, 1806528.5556450605, 1706887.7527207755, 1832061.2546336276, 1674103.5117864853, 1849554.9034591133, 1813849.6292923628, 1705526.5060200305, 1693636.6883057167, 1652260.1468650731, 1660489.9799772247, 1826590.8814996479, 1726882.9462843568, 1741260.0753085755, 1764566.965895087, 1678318.7165909817, 1785685.8455677824, 1811885.8134079648, 1768516.2630682783, 1740564.0260041733, 1668508.7449140681, 1674932.1003200093, 1765555.088621106, 1699105.8048559853, 1651326.5786729571, 1816442.8487679877, 1826715.5978729553, 1657998.5056802693, 1832167.1349628945, 1709701.8834443346, 1822917.1797590142, 1685303.4149616994, 1682656.3592340548, 1681179.9446954182, 1661541.7383213532, 1812809.1792673168, 1734614.3951472112, 1743049.6134529833, 1828434.6059753383, 1783884.1652474569, 1657099.2360447035, 1784463.498512487, 1818990.038654978, 1826978.5806513366, 1685565.5264709971, 1680486.9937485498, 1679292.5359333667, 1651371.6280167964, 1795611.1468977486, 1839461.4999044887, 1819601.0363686467, 1713504.7314354228, 1686144.00248193, 1770210.361785576, 1655549.864261894, 1680822.0242052253, 1695669.816889299, 1780294.1869846259, 1662375.8440627106, 1801834.754388906, 1660060.719713085, 1748720.0052452267, 1708339.8304322201, 1678622.0790130475, 1827132.6721441636, 1682238.7383687778, 1782148.6756095157, 1684713.0503153745, 1762869.2151688721, 1771652.4983389261, 1825450.1529496273, 1748989.8641999662, 1810045.6290716827, 1762296.7709674668, 1769379.6627399328, 1725821.805553248, 1669995.569248782, 1718219.9144652705, 1787723.249881443, 1783182.160652883, 1830170.3623588432, 1663373.067475339, 1778843.8467305694, 1670843.5302830846, 1669028.6079568476],
        4: [1754377.1967154813, 1813747.923173935, 1701420.7722478765, 1795121.5634541034, 1652427.318271783, 1742051.103546184, 1839701.2996523832, 1729890.889159259, 1733212.9560200241, 1724345.7851519424, 1706638.3077280112, 1831703.7307055364, 1670666.3388956613, 1826683.1435461722, 1748794.9495217209, 1847742.456784422, 1676871.5887256875, 1697012.3657754336, 1713760.38804338, 1709360.2373281887, 1757986.4555266472, 1680833.471566601, 1841563.3990992445, 1765557.408568947, 1801020.7062180743, 1722100.1056470461, 1847107.7351405248, 1829666.6587743144, 1778194.243280089, 1813755.7729366221, 1711357.8827793165, 1777639.8678762845, 1848374.1963192583, 1832822.3657242828, 1827646.5053514303, 1727544.660332654, 1780816.736267927, 1725274.9364418427, 1833474.3713366475, 1659477.9887348549, 1672150.6735168495, 1707321.3094709562, 1680656.0272473656, 1716746.0210907245, 1680124.6921469748, 1804834.427968365, 1814670.2732888858, 1708125.2362354386, 1800365.9983114463, 1807961.0124493688, 1711361.846916653, 1730458.517993076, 1698236.1155089848, 1822119.3837886865, 1823952.6891452044, 1683662.9982291304, 1823692.5476479947, 1758121.3330161443, 1707806.8466587896, 1788106.6595139436, 1737149.1956968438, 1750980.5734576315, 1787611.426798424, 1706740.531584293, 1692511.7365131197, 1742084.950524188, 1801007.293902425, 1716499.0056873558, 1715657.2526118301, 1792789.304258858, 1764798.0192929814, 1670591.9967592163, 1768630.500428212, 1752260.2358531926, 1655485.8877197928, 1761402.1999457288, 1698690.6617598943, 1693440.7462820334, 1783114.8150614584, 1758395.3627887866, 1708016.6237856788, 1824371.1999574827, 1658809.0127970125, 1672786.1469323994, 1834183.1906416386, 1705770.4146421063, 1679084.237674211, 1670130.733252064, 1845644.3058282924, 1724211.8070215597, 1659080.686716819, 1691134.963983063, 1718697.4463681565, 1697319.9530822118, 1787352.9407924088, 1703599.11510474, 1734147.3745981348, 1771125.1779330627, 1837477.303157695, 1701335.537199358],
        5: [1773200.283824645, 1790903.8316460487, 1680459.1094337122, 1715597.3194705492, 1699826.3375353676, 1835773.8995617877, 1752950.2004293292, 1716604.2130130741, 1654131.2010083122, 1774508.0304675552, 1747288.13267503, 1730398.5477472865, 1727569.991215179, 1786029.0463660937, 1727993.421309899, 1693744.7571458407, 1740103.653292133, 1779918.0613205219, 1711252.2111580095, 1753694.7732389634, 1751833.8773315432, 1795974.990417707, 1745212.5086043458, 1793057.4711228646, 1768845.6799958462, 1817067.8521470586, 1702614.4345134506, 1654050.995472312, 1756804.6878968724, 1777559.2631724435, 1699692.6154595797, 1681669.9281039187, 1781262.5748638045, 1780696.9761017421, 1802754.1227763703, 1707946.6018941533, 1807110.355757523, 1704244.9886843688, 1843286.4632519872, 1796088.9261807054, 1802300.710840625, 1816888.0871623966, 1702973.065325736, 1731134.0491693881, 1786718.1245780296, 1714565.5851984585, 1839959.9670940046, 1692557.2265982116, 1760227.5911461103, 1770750.419750306, 1724164.447016464, 1800438.944942678, 1711244.509697555, 1725584.1614921407, 1836083.8925678253, 1773024.8642123886, 1685038.8419391627, 1764605.4352226076, 1787321.1648684947, 1791815.5195493046, 1762419.6018841383, 1686054.4185556057, 1742218.8527193577, 1682799.6315371552, 1837957.9836461607, 1762662.5699961272, 1681128.158265004, 1785811.2554079157, 1802244.8240390017, 1802457.427334264, 1775797.402156682, 1736095.2763542451, 1833360.2817767172, 1835793.6256491772, 1669737.225581218, 1774772.3636958683, 1754332.9276161676, 1733911.4400902397, 1772665.5395620968, 1770763.179744652, 1772980.1768250596, 1721581.1968602436, 1843552.1601565715, 1698635.300278215, 1744066.8112559468, 1831222.2618853627, 1681905.8506809499, 1671767.1646687444, 1666496.932454509, 1706343.1608900446, 1784362.3935071703, 1793595.6370111667, 1838758.6861499369, 1676124.9288318967, 1687788.322281732, 1760382.1717740195, 1740443.439514115, 1743321.2263916908, 1718843.9529395658, 1726246.6228883837],
        6: [1720140.5496472495, 1742562.1219572434, 1707938.4776616974, 1706163.3084857087, 1700303.4295370034, 1659418.7828381374, 1720006.1680372355, 1707102.837422472, 1804219.2800540603, 1748246.8013590113, 1839937.3977211667, 1761330.045849065, 1804259.6575666356, 1676145.6115611661, 1788930.178874524, 1796036.9774173135, 1836093.6524939404, 1829003.260544296, 1755639.0763218815, 1705137.751003171, 1722501.6425069706, 1705328.7496743056, 1795385.0015697575, 1757522.9936250385, 1735326.0996999491, 1770953.9400198972, 1734122.60491633, 1695859.468201617, 1737721.3737307505, 1779163.3590254374, 1735115.8578310728, 1772286.2591443385, 1675186.7700775333, 1681518.4188410428, 1797331.5798365064, 1798556.952921536, 1788789.637595021, 1795264.4765179732, 1777381.372773153, 1703649.1427895394, 1789768.6353426315, 1706149.4625340896, 1667487.282664648, 1830865.4418626146, 1776405.7766533503, 1710715.404546099, 1798305.5658112133, 1829775.6631922487, 1672186.7955213366, 1657556.041107551, 1774249.117310075, 1720842.8951779832, 1845675.1769234221, 1784215.6727896254, 1671044.1328321768, 1722504.8303621006, 1797408.6716138436, 1706535.738431964, 1847528.2619252042, 1782013.8215356625, 1761874.982683548, 1747647.9227528754, 1722940.0759364467, 1793757.6197061357, 1719912.515992512, 1839300.5138272266, 1795814.1417099403, 1709440.538315169, 1672958.6200530222, 1838557.7225039767, 1756910.2907651383, 1693456.2100526458, 1760328.1856773018, 1797490.6506628678, 1816827.035225318, 1774446.0474018357, 1776337.0554909594, 1794172.1607417252, 1840666.1089235172, 1688813.6720749505, 1684842.2450561516, 1814332.7728300246, 1803078.4897720069, 1738283.4173839996],
        7: [1710044.8810089247, 1698219.937918808, 1720750.205137029, 1798143.5337756502, 1748735.6500752654, 1667650.678366514, 1698496.4719496316, 1722796.7659845748, 1835969.8251509494, 1710716.18329899, 1748244.957378888, 1715385.9063791367, 1798058.7892218225, 1671671.2836906686, 1730502.2151743216, 1834418.0615442668, 1733268.9723351612, 1798617.6240447334, 1766312.4412279876, 1806188.3747650525, 1797205.9084362667, 1708470.4174169155, 1720099.1663731544, 1708417.417563821, 1702568.8648957475, 1702825.5074441063, 1779007.1149890458, 1831681.5990181167, 1769307.8641230671, 1734280.6838577841, 1805101.1489427139, 1702655.7884929716, 1836061.2552769803, 1709799.7763285607, 1768999.5111755966, 1725132.630634467, 1670022.6588947831, 1711559.5198081234, 1745363.3587805533, 1798533.947509058, 1761598.8734076475, 1738045.5827857112, 1793453.053103015, 1734937.177638006],
        8: [1668211.419429071, 1770054.2952716623, 1670453.6841783084, 1757718.7432357331, 1749575.3266887472, 1722520.114160102],
    },
    [RowsType._9]: {
        0: [1993027.9745984317, 1859700.8524775843, 1908539.3123595577, 1916720.0535736762],
        1: [1992473.7415385759, 1887565.5349089417, 1831875.7666147798, 1986812.6362426237, 1999844.8336399253, 1860937.8645299815, 1995378.8791090357, 1819837.7376597465, 1932085.2848019195, 1841742.9749603344, 1991686.8858987477, 1806639.584523308, 1837631.5511907786, 1870032.8720590333],
        2: [1866746.7445494474, 1961677.4161193508, 1943327.5953074477, 1908205.6973953585, 1965238.4160573178, 1913635.4878269415, 1996867.0677830281, 1931076.4329034684, 1893209.5648073917, 1881791.2143179106, 1937688.2974802612, 1854948.5952140375, 1869575.6320563226, 1877334.2044042372, 1876987.5192988848],
        3: [1891168.2208997472, 1842147.803132246, 1824275.0423091662, 1907708.5377114052, 1876011.0347011576, 1926273.9172484283, 1964804.5235680393, 1845892.8343241806, 1813018.7622465277, 1990503.2970947782, 1863698.1375247862, 1802918.7775226568, 1905274.7300557462, 1816466.61646962, 1814711.7284885915, 1834543.1726313801, 1991033.14619004, 1988520.1142336053, 1904655.867714669, 1844377.2303124652, 1984113.7804005386, 1939025.5912050987, 1838283.792706832, 1950421.4834346066, 1941849.6999973683, 1920476.3732008631, 1996816.9359445204, 1874857.858221578, 1959687.5623062958, 1933954.5881759198, 1940572.054955069, 1842658.9468533325, 1816437.7812118065, 1845081.438755571, 1870354.4684166345, 1879538.8046805728, 1939611.6954101967, 1875975.5547845347, 1800258.0637117047],
        4: [1969230.809583635, 1908769.4137574278, 1904755.9443877381, 1943312.662233561, 1954627.755069218, 1989035.9730594927, 1993572.591568953, 1933527.7243020728, 1950882.4161893383, 1966699.930017991, 1962020.8685344239, 1807600.1227521044, 1810097.14599693, 1921977.5148151873, 1845512.621088911, 1958809.7939058482, 1999010.1657867339, 1963056.3723681935, 1903640.6883340704, 1993640.1528642494, 1865823.8876483245, 1886658.6854176626, 1867682.7642059142, 1907589.1883253017, 1926395.7781927304, 1919864.209872936, 1948058.7153541103, 1846521.5878792414, 1937774.4329359732, 1870132.6793798406, 1898990.6626609473, 1810640.9917881216, 1826551.27638429, 1960115.5981136619, 1828429.8601844208, 1826477.4930368122, 1878709.9354955049, 1942844.8288715447, 1808071.2036329661, 1907832.1145727595, 1876568.3041978749, 1941033.2589846193, 1883805.8181322597, 1806977.3974110887, 1975486.4781596602, 1865444.0460793895, 1898403.1545159875, 1877171.3514126264, 1846885.0201102886, 1949078.287366837, 1922777.1871885364, 1940264.3242746096, 1958763.4105441708, 1913307.9894108102, 1996732.2523785736, 1935272.792332184, 1808646.6093399075, 1802056.2263696385, 1989007.9950132088, 1852263.7218026835, 1823953.8687226705],
        5: [1958620.5229593143, 1883289.4010259514, 1849740.90329187, 1812258.9816238405, 1993884.0213386847, 1808352.6782155947, 1839952.8999779164, 1910825.237342877, 1968048.3405257019, 1862573.013966224, 1853501.2533289366, 1877735.7495157812, 1824789.0747773426, 1801015.0177554514, 1950803.4444254239, 1979916.1075048333, 1828698.1033326169, 1880640.9943403408, 1827287.4042639548, 1850591.321404449, 1901925.185690013, 1863129.1240329698, 1992976.2326644484, 1883321.8832688865, 1881013.639181712, 1851080.362501542, 1853681.1175939317, 1907459.4211486091, 1858035.2833693665, 1861187.4663651937, 1971392.736298357, 1804994.9026207207, 1897108.6418089098, 1940550.636963355, 1914400.1901546451, 1907318.4386006403, 1928611.975444962, 1863434.1507633443, 1901992.443007434, 1849189.014477674, 1857932.685774641, 1821157.4874649, 1931805.3513070547, 1961900.3120489966, 1984740.7361634276, 1972784.9415822336, 1859957.156369157, 1880845.3948183958, 1822932.068572606, 1878414.3463872352, 1974502.0344268288, 1820343.2439111082, 1818774.3427716992, 1834249.7045200393, 1900112.5916431642, 1907186.6678270823, 1852692.279352491, 1960169.4145990938, 1950725.327848325, 1946828.4751449563, 1939709.849299305, 1868406.6514686653, 1923586.0880450872, 1971524.6751467534],
        6: [1884700.2705000918, 1889954.1136320622, 1898532.3925910823, 1817416.689602184, 1960353.3898781247, 1819471.8767033913, 1839149.3594585874, 1804874.5897782738, 1911083.420578403, 1825467.2027668983, 1933101.2721815465, 1836912.6248000623, 1942213.24001281, 1928477.267070155, 1927936.8974575158, 1808389.103703723, 1901725.304486625, 1916007.7605504584, 1917416.046978021, 1983577.9979622718, 1918173.6218555316, 1883273.497050854, 1814656.1076567564, 1918437.7365400423, 1815745.1158332645, 1952165.2710189219, 1971871.5445051359, 1911050.8466670006, 1933703.8462816232, 1892287.5619100656, 1868636.1028570873, 1801245.344593255, 1981375.7212124115, 1956187.28651047, 1809778.856865525, 1977257.5843786027, 1977502.4192840457, 1978676.111093488, 1800970.4851074668, 1919556.4156998072, 1924857.6828372518, 1839631.3695607577, 1881987.3487657018, 1955651.08760665, 1907475.3918870676, 1947182.6726727667, 1976608.0670624974, 1948212.6230532245, 1824760.3930452187, 1994506.6569621365, 1984719.8748351443, 1998945.9609942907, 1910274.2835439523],
        7: [1918177.3713744355, 1914890.3678372435, 1924796.546780997, 1977890.8810094527, 1919493.2456292778, 1962064.3055616473, 1976364.0204897244, 1969156.599999814, 1947337.096768978, 1977961.7212234081, 1971676.6491319197, 1905703.557860544, 1914882.3741336463, 1964316.7346672853, 1916857.5191362987, 1828934.827394855, 1966256.4037837463, 1893589.3037150057, 1821659.0073181023, 1916109.769575407, 1884901.6337862413],
        8: [1901171.9225763879, 1928095.0807047512, 1846820.0062129132, 1895587.3343962557, 1812096.9577594292, 1876567.9302086278, 1923878.788388083, 1978479.2120222417, 1925411.74586556, 1923831.6285981417, 1949015.7004407018, 1848599.887463662, 1924082.3109749313, 1977439.3784887257],
        9: [1986122.9974476648, 1985843.9108997849, 1891001.1207052935, 1808423.7329232374],
    },
    [RowsType._10]: {
        0: [1997822.998878434, 2020960.0725069887, 2048340.8077752893],
        1: [1962409.3780583558, 2140571.9065968464, 2135096.1799029266, 2031968.3423557617, 2037939.7323072862, 2047841.619410554, 1954825.1942843117, 1954786.6204951864, 2049860.7695693988, 2010369.707288108],
        2: [2016230.072667492, 1951165.6863386172, 2048286.2065452673, 2015643.659297277, 2092919.4545070922, 2038412.2748036499, 2112115.7211708827, 1992501.9634165172, 1954896.7438354066, 1987265.891805564, 2115874.861551995, 2149256.5827684677, 2134160.2328149523, 2114538.3476402047, 1990592.3744197604, 2065404.7822482008, 2064168.124685723, 2004042.8250161386, 2040599.7022869042, 2037606.020609805, 2057603.0804795302, 2112101.1961801127, 2089288.9197159496, 1964670.0877806926, 1962578.4020161333, 2118319.328222899, 1961800.4790085873, 2002609.8732307884, 2023780.9393865054, 2022026.5765354706, 1963553.1342456343],
        3: [2084748.3497585722, 2016550.9905366267, 2088556.7867799664, 2094961.1292339459, 2122686.030877384, 2128649.921915636, 2022586.3490661797, 2132042.090552471, 1960395.907224347, 2015680.7132198259, 1951760.895514446, 2019929.7596368052, 2148254.3072727337, 1968264.158682925, 2088571.4212241336, 2141788.594105469, 2145770.8342136526, 1958690.5664270942, 2029283.2255983842, 2127131.485490126, 2048160.2946771842, 1994805.7597398888, 2131076.499135471, 1968417.2676016209, 2016187.476310901, 1988896.7788662117, 2083749.8241201192, 2005820.3261143432, 2136681.3832779466, 2041746.3482443178, 2136270.9934167396, 1969583.2943984543, 2124117.756982887, 1963605.5231414991, 2014889.4483696176, 2100928.0813600193, 2041390.753973394, 2064333.0610183426, 2022602.0718388048, 2037224.5749703192, 2143880.33281082, 2082984.33637784, 2050751.6218059133, 2112048.690662155, 2022066.3976060962, 2048989.85556321, 2044028.6320913078, 1981854.8014217338, 2021020.1173575837, 2029482.624830698, 2140957.950082969, 2039386.00066935, 2036719.0368175423, 1956949.9845167997, 2136774.7048641383, 2025476.0620505137, 2113026.9477421707, 2136637.184752046, 2021676.853338919, 2131106.3737958386, 2111868.286100593, 1994699.4239340345, 1984109.785393928, 2088750.5528314575, 2122861.6224483303, 2034563.804027559, 1962721.195907205, 2012404.1839405405, 2088832.8315822545, 2114394.5507333186, 1971551.782084179, 2129726.540790182, 2024712.1746157978, 2071338.668312632, 2113361.4965207097],
        4: [2065315.1726596525, 2017897.8257120678, 2030749.2474266945, 2139748.736452189, 2123672.646750923, 2074776.0455967255, 2083137.3511344441, 2049386.982607747, 2026581.3767274686, 2017479.2719746458, 2137667.88677769, 2029314.7608246515, 2018657.714454196, 1980457.8341304637, 2115886.173190656, 2083099.9975030702, 2057333.5994241496, 2124294.137903595, 1965395.3625401298, 2118354.967688744, 1968708.7593208968, 1956609.7933525161, 2090902.7275830635, 2138698.7429131786, 1966035.2781554873, 2046382.852593051, 1956992.8638330118, 2066325.6261360433, 2107466.8551017875, 2112265.1378409117, 1953418.0762282736, 2046996.03821437, 2029006.7344217624, 2095452.950818405, 2123271.986275246, 2042698.9562868269, 2144359.4553303085, 2040299.274500942, 2085846.8386868518, 2005192.149559317, 2065853.1108963066, 1970945.6878613948, 1992813.0466008517, 2002361.58995284, 2059514.9612889597, 2016153.1761745235, 2028985.570672913, 2068931.5076872595, 2134062.6081758365, 1970468.855190981, 2015136.4324078117, 1989009.606593628, 1973076.4795981252, 2032020.5407184884, 1999125.6789136669, 2084210.6524381565, 2031710.182024383, 2123909.8408400854, 1967343.4197599872, 2107140.277316797, 2030360.1554211597, 2050191.7518652163, 2003911.12467065, 1960436.2455712163, 1969630.2512354054, 1989240.8051701768, 2007834.447479949, 1968476.7994714861, 2043217.2618551436, 2135207.2281994317, 2047807.5374668953, 1984394.8644414686, 2104476.4282330307, 2139144.2709512413, 1957665.5403290894, 1953504.3338055194, 2059154.0427193143, 1957640.4846027144, 1989957.9492354663, 1997342.1652625105, 1955745.8522736817, 2033137.0760279, 2084136.1513178204, 2063906.2582619325, 2116258.882709466, 2062148.3645198657, 2107675.569425674, 2033525.244819533, 1958763.3771975245, 2005606.7804020296, 1992771.1176740588, 1971882.8610723629, 2043552.4749265707, 1957161.3916689595, 2017456.1154457305, 2059095.7670330803, 1957001.712205128, 1960660.5687718098, 2069308.1776251344, 2057040.525599407],
        5: [2094919.844429963, 1994315.0847319302, 1975186.7838574552, 1973567.996509673, 2062032.8539423554, 2055754.2095766594, 2051003.9288216608, 2029054.6283404604, 1981157.9642253416, 1998687.3888298643, 2014323.4836916742, 2071324.5953670016, 2114263.6683810935, 2097290.0637231898, 2000792.7434722115, 2065696.5176260541, 2129413.780190839, 2060665.1572385854, 2004682.2348685567, 1984611.8406296798, 2018691.1853971058, 1980531.0622757906, 1965020.69113782, 2105701.009047711, 2127380.1897324286, 2002171.20001836, 1984931.1336417517, 2016811.6111445187, 2000725.3525359593, 2134632.862723193, 2035026.6072151768, 2115732.0781264193, 2003498.279171736, 2053743.6604550243, 2096563.9040340718, 2084222.0268387615, 2065921.2278115475, 2034849.9187612056, 2056033.1062434462, 1981098.2668926525, 1953349.1084164495, 2047814.002605275, 1964870.161727903, 2055259.5669924223, 2040052.3779290828, 2101412.6362312357, 2059259.3437172184, 2019889.7986148973, 1977051.0636301595, 2059525.4811327981, 1983738.965234081, 1979011.7337783726, 1996109.72005118, 1958840.7738962434, 1975113.3647331663, 1978658.5269771393, 2137185.685989411, 1981784.2487216254, 1982487.5222944254, 2086442.2984918985, 2138454.0006588935, 1973698.929015362, 2145665.4902204415, 2045051.256674874, 2107270.2654256322, 2017723.0300518682, 2066614.4702515297, 2094037.375733642, 2051419.4091229963, 2017446.317000029, 2003643.1437540795, 2119690.7020332613, 1970925.697299043, 2137586.582513199, 2001066.3518398118, 1995546.8702220563, 2119784.5488253576, 2127816.452373859, 2053226.8285550883, 2068101.1019384123, 2118810.1099755196, 2041660.5616248762, 2047922.7111082643, 1975586.6353018472, 2116418.3085968634, 2067212.6202458313, 2031535.2150298296, 2143867.7949001454, 2134710.251843046, 2134456.28508361, 2066517.8020479367, 2024768.917696033, 2005383.819408005, 1952131.7002299165, 2117833.5224699634, 2015849.0903958834, 2001482.0675151905, 2147339.7920805905, 1974686.489013164, 2026252.3089536398],
        6: [1982456.411328899, 2041917.241725873, 1958750.9908982238, 2090607.434266531, 1955022.244203198, 2108344.2987649194, 2026315.4727825357, 1981753.6451064264, 1963911.207570341, 2071236.2814310875, 2076399.220731615, 2063813.977208561, 2065023.7373901901, 2122506.3864673926, 1981981.8415916597, 2054525.1040971302, 2060537.1781672256, 2070422.252252802, 2009770.516796863, 2100144.329361931, 2149825.3161235396, 2088219.116328348, 2008489.5928104117, 1968214.9089268993, 2081647.179579736, 1999752.0216701985, 2074119.1421598773, 1965922.582590342, 2064504.0612863172, 2044575.059915611, 2023462.5800013891, 2070044.2288826574, 2031957.7972611068, 2073627.2658355546, 2097840.31689263, 2123031.640506499, 2027339.1887193962, 2066663.2052013017, 1958977.6064475446, 2042365.0949953261, 2079678.7403713563, 2074457.2687419052, 2035519.2102483222, 2114536.8505009552, 2052614.427492552, 2129829.8407590427, 2059889.760872757, 2018794.4390851343, 2086043.0858035497, 2073523.859895511, 1985041.3329039637, 2029409.3212029003, 1969728.3805753684, 2056640.8506389863, 2003786.6824542626, 2105418.182494858, 1968933.8268204764, 2094727.336698284, 2065985.9801261046, 2147322.91175886, 2112696.701843639, 2040798.7522430236, 2014301.3167281817, 2082937.9922965197, 2088839.3266434872, 1966722.2634920166, 1987685.1393033033, 1974041.726067443, 2037117.430425362, 1995384.9071679527, 2075757.9933162527, 2093106.8352230147, 1984456.5498648246, 2102828.078813647, 1963466.0493988448, 2016802.750764591, 1988852.7992306252, 2071285.1155748311, 1996974.2573709872, 1977385.0006522958, 2060321.590783822, 1971272.5636908868, 2008899.183352756, 2050798.935159885, 2006160.5505914893, 2037839.810824368, 2146789.733320275, 2102530.2291633026, 2001877.8561583892, 2080363.9032026988, 2108299.7110888446, 2116511.0019630017, 2016048.0095109304, 1976227.4185614446, 2078259.6804244781, 2117366.45831823, 1957923.6768307432, 2146810.9417013354, 2144532.182656269, 1967028.8279135819],
        7: [2043041.4150851339, 2072645.2520415706, 2085436.589931098, 2105401.16737879, 2144022.1806258643, 2095119.9546048618, 2058736.7374985476, 2116576.0487304856, 2101972.56282418, 2045621.416772211, 2148614.246188806, 2098319.0977020273, 2071074.9459445947, 2121516.759774576, 2082338.6932998206, 2070596.4490819352, 2104977.3140226197, 1999756.8624142322, 2116793.8078877963, 2019617.0876642026, 2072268.1795788298, 2050319.8464403213, 2127069.548721947, 2007058.2200400236, 1970864.8881147448, 2103483.4533445225, 2068069.307992335, 2147485.7243566844, 2132899.107650658, 2082411.0345803662, 1973550.4100371154, 2103541.7897851616, 2107330.232229379, 2047056.962610229, 1963897.0566400767, 2057918.019786013, 1972602.0071975552, 2097694.970838011, 1953415.4347333913, 1956316.4550364488, 2073412.176386405, 2094481.1978029942, 2117753.3842719756, 2056598.4477651918, 2066819.9392639173, 2005949.8379299867, 2119203.2029654826, 1992135.7581094136, 2059666.053611036, 1998090.2890490189, 2137499.5459760525, 2051826.3519546948, 2147295.103832239, 2054734.9778659497, 2116432.122166917, 2113934.1131505053, 1977253.2527748805, 2108936.4694636124, 2128665.564788329, 2073659.9839796799, 2004810.476961763, 2005292.7704455755, 2132462.3516754406, 2090894.8297247356, 1966882.2128053796, 2083895.7878244233, 1979803.2840230968, 1994083.162038321, 2103484.4510236974, 2102232.317299655, 1968764.4945911393, 2062269.2615263981, 2104811.848501105, 1986773.215342903, 2083473.2776511146, 1973683.6702785091, 2066428.8859897617, 2064875.4663383272, 2098912.8055769224, 2058869.801461846, 2058351.2629356273, 2046944.129823339, 2130222.221027622, 2067942.2086757617, 2069327.7119124238, 2149684.8367519653, 1980846.4866817875, 2022896.8772649881, 2076458.5209840331, 2076826.3308803532, 1960694.9287565898, 1988150.8083700447, 1958123.1129862189],
        8: [2028186.6896935722, 1980492.0752069918, 2101944.3730314886, 2093186.6538652866, 2068207.1132782204, 1950939.0826806186, 2101890.4749114527, 2052104.9073618823, 2087197.9992585164, 2080759.2379033, 1983572.66362522, 1977714.4575843771, 2074480.9896016647, 2074088.917528271, 2054203.59969252, 1983101.0263272594, 1977352.3747938308, 2078601.0101859865, 2115525.4250833825, 2081164.1589418205, 2142963.383590939, 2005587.624956836, 2064617.7745890373, 2108164.6452733623, 2117410.771970804, 2013015.3863481595, 1986385.4028838526, 1977477.934195049, 1983563.159996952, 2136492.360798485, 2128726.582881695, 1991643.0538945696, 1983439.842611025, 2076187.151247823, 2075960.3494848227, 2069555.8071326793, 2027488.8478204175, 1982843.7138920038, 2103969.8726518448],
        9: [1986793.7948684837, 2073861.460309586, 2081473.861954099, 2104818.7175581767, 2148314.9254738553, 2137206.3154780823, 2058998.6627683288, 2064868.554397253, 2071733.9618098228, 2105141.7714474443, 1983263.1143058764, 2014006.908319167, 2070566.0223958022, 2077252.3585206068, 1955907.4743033543],
        10: [2138434.3446141854, 1991541.5000263515, 2110060.087303763, 2082385.7366705951, 2094947.8751106926, 1991374.782114804, 2058658.8508370488, 2103159.3293822915],
    },
    [RowsType._11]: {
        0: [2217583.2175909104, 2196714.502935293],
        1: [2321671.7563752905, 2204575.1707174196, 2272269.4542518617, 2219599.0303943064, 2167283.7165401806, 2200530.932367809, 2323781.272690087, 2272417.722772999, 2202055.245387404, 2307926.0922466586, 2280940.715078492, 2291414.584821897, 2196192.9664563513, 2168786.057902371, 2177777.0102791856, 2205531.917758892, 2273683.591668348, 2308532.0747587476, 2178402.4731185758],
        2: [2301173.737204291, 2349996.2241676543, 2169925.7155120983, 2243168.936926321, 2226689.367560939, 2237387.922337694, 2346569.5573477554, 2229421.363211915, 2154017.593656637, 2182186.800967667, 2287323.968980429, 2316887.224118427, 2221156.4640311445, 2323919.3524984843, 2225668.45944236, 2240311.3045192864, 2298237.571455505, 2308452.5289058606, 2252799.0537114446, 2346501.054928267, 2303402.231086376, 2176297.27435138, 2307931.886133355, 2162024.8143208134, 2190894.444206665, 2228423.8254275317, 2191916.403214522, 2200717.102851805, 2301176.3586989143, 2161601.6278095306, 2268782.950662375, 2203928.2375896224, 2237169.1736793746, 2306911.9923934294],
        3: [2229129.4674442336, 2221939.6192613537, 2269173.290893467, 2195946.2984065465, 2258176.531507421, 2222568.148251382, 2293167.538745144, 2230363.8127384256, 2166701.8454277143, 2331263.448318301, 2313796.7989665354, 2280003.348359714, 2182602.4191727554, 2176541.955098561, 2219302.4822945655, 2243215.487014584, 2323834.4886067095, 2161558.839018999, 2295361.0011476134, 2316577.3382683727, 2234430.028782824, 2302825.153369477, 2305081.9860426104, 2332450.7683667457, 2179810.845651601, 2263976.4277962307, 2197746.1041438654, 2291148.9384327917, 2256037.19129567, 2223698.8274162463, 2196664.0194153776, 2191716.958111283, 2346981.856385537, 2174040.6359487204, 2297929.2967134523, 2226288.938695388, 2175021.2716158107, 2168265.7910975935, 2248939.9406242375, 2177453.5428846655, 2204716.6990598384, 2308494.2390222927, 2295522.0165625312, 2192166.3712042384, 2221241.725942254, 2209796.0859364187, 2297952.199645892, 2156732.464015608, 2201409.808829025, 2230274.27369787, 2151358.74766959, 2221032.068498305, 2209480.8678677715, 2196269.031156156, 2321369.839052034, 2223368.337262413, 2246550.2781417314, 2155271.7181986202, 2191003.4153684266, 2240951.8725207727, 2195137.537949352, 2303919.3569108625, 2264578.519856978, 2186863.7207841813, 2210515.145315125, 2201077.034140927, 2294041.966856573, 2217815.400613047],
        4: [2154600.0921254414, 2268552.9452249664, 2272803.5592594845, 2302862.330110585, 2297670.0316072293, 2232579.847307878, 2198599.59469962, 2311978.252520506, 2322492.589055869, 2296498.3359790086, 2302588.8019530526, 2194863.644836344, 2259912.6460252367, 2153209.63317603, 2271346.428231751, 2239390.276457011, 2326636.2491391506, 2154553.921492413, 2256903.1884810543, 2318096.1076597064, 2199756.289197701, 2221497.0756096495, 2286475.955080898, 2302119.3253967348, 2311730.8364318735, 2154828.711635561, 2222015.4650790165, 2154363.251096809, 2207076.477914764, 2235131.714100246, 2306634.3983479314, 2163524.128673499, 2325738.088590716, 2228633.3067232105, 2293348.4656604785, 2314901.2690392034, 2209812.958052589, 2153812.2992718634, 2311047.835753584, 2257355.2478105617, 2235109.8038703646, 2323691.24487262, 2265921.6973211034, 2343536.7065280746, 2278793.4157182337, 2280107.857688666, 2298945.2992763096, 2309326.51586395, 2176447.727729991, 2298115.479698792, 2182603.5111119086, 2259010.133346763, 2317795.648625205, 2256190.5725075165, 2221497.204379669, 2151077.318725609, 2320687.460510249, 2227474.759532756, 2305799.345452731, 2192353.807153886, 2214216.2733680615, 2182862.0759804565, 2175503.936273351, 2205822.5008768663, 2159388.028609548, 2260794.9457885777, 2346619.3812677125, 2242594.72240804, 2311991.511649391, 2183028.5003215307, 2182548.711854929, 2197903.262382966, 2197578.6177434493, 2271509.146164258, 2247851.3542845296, 2229179.066235194, 2246856.4257212705, 2304121.2304798467, 2177565.0376963695, 2320500.16081752, 2186622.082111411, 2310262.2708351715, 2237828.8400941384, 2212658.0948759913, 2213224.887259238, 2269098.7884264872, 2168306.538672245, 2199718.2174393013, 2209380.57141521, 2250376.9142744658, 2299451.1617125566, 2341935.0380723784, 2245674.648356277, 2290381.2689988213, 2176072.351936498, 2309109.581264023, 2346223.3425168977, 2300490.1857070928, 2261050.334620742, 2238593.4788990696],
        5: [2160851.4509589183, 2307277.810165658, 2220503.8083744626, 2193474.110559166, 2240537.594443563, 2154501.516849503, 2156642.818602795, 2293784.5079352334, 2347476.431746451, 2294293.649093675, 2264554.770854865, 2287112.2113536755, 2346276.8060572776, 2210673.959226645, 2166397.919030965, 2241855.428376, 2317289.674919811, 2157612.1605273504, 2336357.493531769, 2182170.5716563854, 2209782.9933376336, 2294931.2181486017, 2193966.0171440714, 2179634.023528812, 2230862.7963139033, 2157115.4051645612, 2271947.2691455777, 2166561.840071397, 2213782.939226659, 2233599.8459962844, 2247089.8166741747, 2258283.8904924775, 2234945.7471259125, 2250201.868029927, 2282398.3338645035, 2184386.660620175, 2248995.7069148, 2284514.137658783, 2238912.809975239, 2327803.6143448763, 2327288.9527199194, 2304784.849870826, 2234440.384673353, 2153424.7689760877, 2224530.1023379765, 2282151.4138554423, 2154861.4058104306, 2205302.4265604443, 2162545.4003717625, 2335693.594697671, 2203705.250495523, 2308837.780501437, 2228891.797464421, 2216836.6975375004, 2259874.1660578633, 2200979.18153037, 2203110.7175890757, 2159886.912089473, 2313990.4887304637, 2310143.7414482133, 2248742.300398686, 2208884.7255070414, 2323745.8605428454, 2166244.796442396, 2299271.1566081517, 2262206.2910594316, 2297040.2946853884, 2310245.9797227415, 2184193.9291671845, 2315451.345215424, 2223968.6039853026, 2212966.461967268, 2279006.1292311777, 2285636.765743811, 2347448.9187452006, 2268888.3222456556, 2281980.547795389, 2200071.391945382, 2208013.550649489, 2251097.993292036, 2342687.130417419, 2222767.2763196942, 2324624.2933731726, 2194721.063285669, 2312789.6099466705, 2269096.5495023592, 2343307.9400234395, 2272206.0713774683, 2294550.3164903885, 2247232.6267615273, 2307789.6105243647, 2313266.645430608, 2349531.9964737343, 2327287.528495314, 2304178.4230027194, 2264969.8573008045, 2154655.11623314, 2348628.3369983034, 2187853.543691941, 2284527.361525226],
        6: [2237885.739291942, 2152690.3038475257, 2156976.4104091767, 2160369.183529665, 2183598.467851141, 2177446.2576880357, 2220902.5485749077, 2334580.945155675, 2305228.370214691, 2219957.8379963143, 2208236.0020836885, 2233150.9391039936, 2163839.5676081544, 2246470.828405336, 2278639.741951015, 2296538.4297701875, 2332701.211352532, 2159660.858415237, 2331447.8430596846, 2168346.8253834895, 2186382.3222448993, 2242825.203833675, 2203645.091116637, 2173072.513015292, 2186342.998322767, 2304096.112762411, 2153012.473689551, 2238784.4695531013, 2327157.4922777507, 2162385.801238721, 2341521.487260551, 2181092.5110071893, 2283109.2534998585, 2288317.2776802066, 2211194.9455107693, 2323235.3473248, 2270999.9379531094, 2205010.3834913163, 2318230.3455925463, 2224745.5860787393, 2248040.4526845454, 2196086.125411575, 2214817.26623439, 2303541.699745419, 2316545.9814195326, 2323152.8432463217, 2330184.3933960614, 2333207.517567399, 2293383.051825866, 2151387.7167218523, 2284146.800318382, 2212109.0525041646, 2267650.5390501874, 2265132.0794319557, 2303067.4053562284, 2329791.127340368, 2157077.31980491, 2160268.714785001, 2176726.5696797613, 2244936.169290185, 2152296.267864975, 2179025.9786414113, 2301030.9060041434, 2239497.212986839, 2296763.85682824, 2303649.932294179, 2205301.0428865505, 2270563.7907559033, 2233024.1045277207, 2326582.1244617966, 2293714.6762186703, 2312546.4627657984, 2323926.004669159, 2270871.7208971577, 2339435.342691724, 2335144.613265075, 2212729.4688991094, 2208428.3834912116, 2276931.848660068, 2216138.6681052353, 2165506.9888987923, 2193523.4386653034, 2184835.748976818, 2157779.0435117283, 2236995.1298914263, 2321883.6413429203, 2314624.0213019457, 2242522.580082806, 2219392.6908416417, 2169362.4224123196, 2180592.171261347, 2266078.6642934363, 2193004.0111285485, 2161878.617112699, 2232278.839576761, 2312971.053631679, 2168464.6599564776, 2208301.8300962425, 2199698.4910752033, 2218030.426890573],
        7: [2275430.6952201123, 2285777.5955540775, 2300763.3906426607, 2252363.492834487, 2340542.363368482, 2222855.162482938, 2267329.5096523324, 2231518.861529936, 2163428.244322424, 2273305.251765147, 2171447.667160635, 2305717.808813875, 2294786.954596043, 2330981.351025335, 2274910.0208350327, 2199276.661912634, 2153796.1467101597, 2206266.7334551695, 2159597.1371725486, 2337881.0754288686, 2244065.0284891375, 2272111.1174050565, 2302607.625304895, 2163379.7271273425, 2301956.7879058844, 2333708.335067936, 2325351.4225712223, 2220328.8200829327, 2325294.5514749815, 2306262.8574891966, 2268850.7852723966, 2342863.7523600818, 2159569.3957647975, 2334691.0438149683, 2150961.1683423016, 2332510.331634519, 2326613.328941721, 2297381.698560203, 2184931.6695356653, 2260492.665486004, 2160892.419603394, 2251200.8596903016, 2243665.818451279, 2339663.9714254583, 2311377.381212698, 2281401.1613931744, 2236465.112298906, 2285098.2142730686, 2342985.5187553433, 2227493.9664913677, 2169973.915882483, 2293889.9859978813, 2192649.431153631, 2282278.9600175344, 2217621.171464675, 2256795.810580011, 2343376.555248236, 2311924.6683723563, 2160826.321491683, 2169844.74368249, 2241701.569873973, 2194067.7473120512, 2200140.413592005, 2334554.0856445436, 2282526.3253052784, 2172719.469058839, 2225221.6780869975, 2187912.2012661216, 2246693.6591193234, 2332293.871359895, 2241072.997073074, 2214406.9982170514, 2179654.5457146293, 2346856.8465753105, 2339616.4124694206, 2287282.226858998, 2255383.6551030264, 2217391.4758303915, 2288775.064989532, 2156401.0743279234, 2239254.8689998644, 2161502.2219234616, 2278452.085305782, 2312139.8739704792, 2199068.1323843985, 2317361.534886344, 2318135.5221509985, 2319881.1184057877, 2257496.0774505637, 2293817.3065774855, 2333332.5422718655, 2292941.4554486414, 2153472.4494063975, 2317662.9779999717, 2307201.0365086515, 2251091.2899734112, 2253525.773195613, 2207352.6716826884, 2324656.6020778804, 2210993.747671206],
        8: [2187496.0286865933, 2276007.0101582897, 2283546.16563295, 2332581.598650298, 2199474.601226793, 2211920.978352003, 2272721.4262183537, 2177404.348732842, 2283288.6805974003, 2161971.317351759, 2187539.7837619563, 2274821.063785078, 2232011.4958922765, 2184680.9698236263, 2297141.3909973903, 2211561.532959066, 2193807.940155355, 2320319.2645204505, 2337546.6894599465, 2257639.7765331366, 2256067.1677922346, 2215426.4705501, 2273893.3219750966, 2210638.4067736724, 2180342.1720169825, 2337582.2034210768, 2188830.320030706, 2184879.816144568, 2320366.487338608, 2274251.080956612, 2338635.738863255, 2238232.44970406, 2331023.3053629985, 2258389.192351305, 2284672.326471534, 2235824.2107691863, 2169590.7491542585, 2199428.13846716, 2313826.979761347, 2152630.9414591924, 2315852.813197259, 2270791.232109468, 2297313.5420694053, 2337086.475742847, 2240138.6783429706, 2288971.666228363, 2168583.6789609273, 2275578.428779105, 2250342.499738485, 2207567.7898147195, 2284819.24473598, 2328997.7085620672, 2239906.9275300708, 2198993.6145249545, 2195140.0140014216, 2219960.4356406475, 2260136.907912303, 2325378.0937056304, 2280640.6285469253, 2253622.6413430315, 2154846.8643658883, 2324616.5234118663, 2272296.0403162874, 2193682.751924474, 2315870.6118190573, 2171925.5379115767, 2217960.4075555163, 2166444.767606289, 2309010.5950595965, 2176825.974669262, 2192917.481929158, 2265681.288927975, 2179808.447878471, 2253344.2599503966, 2156206.524724329, 2274040.261588045, 2322223.4072682303, 2240642.5452204356, 2319799.093866376, 2338359.496548632, 2228453.670972294, 2206721.9301443044, 2345431.0638761017, 2183629.8688482824, 2177489.653060928, 2177883.677759213, 2171251.209426392, 2178136.8197475895, 2346151.1814272227, 2335158.1149955974, 2195958.8522113007, 2293533.5859579947, 2202522.9394443333, 2168865.6763217514, 2186094.6244640816, 2267684.5328531037, 2283378.0730571877, 2331494.714898216, 2242613.2534229574, 2296320.531636241],
        9: [2345908.1173266205, 2309481.4434900586, 2166240.371039656, 2294713.561840357, 2317428.965289945, 2275412.6081904904, 2253482.121222276, 2278457.332338427, 2257611.9091678346, 2278016.537476646, 2278975.740049129, 2295411.8534406405, 2343161.211447358, 2282846.141428672, 2307584.6486943737, 2328460.7229591836, 2336604.978402712, 2217285.901577838, 2232622.284721248, 2281389.2351025688, 2245081.9294961165, 2184107.477636775, 2199280.3677584054, 2177225.324500387, 2336818.983716137, 2236401.268886756, 2238855.6815999476, 2193899.0626036082, 2344211.0332075264, 2346176.8358960752, 2337854.2916774233, 2246297.4495874005, 2234529.291789206, 2342715.805597897, 2242472.069278929, 2231296.60232873, 2258536.611792621, 2254486.0610955562, 2208531.0044679306, 2161966.0641960697, 2257351.130461103, 2250701.8731962536, 2184781.093154939, 2258431.1444519586, 2294073.3379285308, 2286624.8152248524, 2260167.520207315, 2300625.229802711, 2188532.8373716255, 2193717.00327498, 2206828.581471011, 2336864.712430959, 2276256.2890291424],
        10: [2262429.1539146123, 2232808.454752665, 2242435.215228684, 2245350.0070467014, 2338367.969136761, 2289627.2535188766, 2345748.686859067, 2297718.528356878, 2207620.2217492983, 2277387.048464732, 2187665.5822186866, 2338182.7269153995, 2241306.8318983964, 2276409.429297936, 2346080.6502655377, 2284730.0488991295, 2262155.760328387, 2289481.7436268935, 2318360.581088654, 2207017.2160357614, 2324246.514443542, 2345083.594293262],
        11: [2262783.9489897867, 2294275.0283596874],
    },
    [RowsType._12]: {
        0: [2370168.992304455],
        1: [2372147.1727282177, 2314064.128199663, 2370889.719894472, 2417381.7095746575, 2449158.989695719, 2413007.324636202, 2482711.9901931128, 2401961.153119354],
        2: [2329410.3344353884, 2323374.5518704825, 2394190.6389952907, 2360831.495215245, 2400912.532689317, 2421539.783640136, 2359525.2154274145, 2473359.4689943963, 2417033.322257341, 2397937.7908189157, 2381384.9692959934, 2316807.5071073687, 2329219.4722067295, 2321970.989214872, 2444139.7888327437, 2478528.8923469787, 2376385.8802348343, 2472405.3625066294, 2383970.562566217, 2381725.8962186025, 2321712.3402942778],
        3: [2492852.817217245, 2424279.0804078965, 2358409.664519247, 2486686.8467507004, 2325488.6216939855, 2321680.241001878, 2485078.6763009927, 2448987.3184678913, 2494564.9145605625, 2414971.112210013, 2384713.7919304944, 2475254.914945254, 2326905.6249875845, 2363893.3057375955, 2407553.2679064353, 2470272.333313328, 2449208.0280706454, 2451847.3252145997, 2356454.3202594826, 2314183.201964279, 2398820.7021534224, 2369354.7492832416, 2376734.0519245393, 2342783.4070190536, 2326024.183594416, 2339977.4156157104, 2322300.58064163, 2490334.1968441703, 2324147.897986684, 2315767.9289255114, 2369835.533387711, 2486577.616464354, 2453709.3162618163, 2426739.0150497565, 2359198.5073533077, 2316808.887721011, 2451118.2204713044, 2464377.693525848, 2484164.914852697, 2447051.116323521, 2376270.7716786535, 2410370.92480941, 2365701.853880797, 2305465.093645274, 2314887.7519238563, 2472428.349954371, 2411665.9655628325, 2405878.350290024, 2329014.25744878, 2384061.2428748854],
        4: [2363542.8386091175, 2444502.6129350495, 2300409.1442961316, 2306288.7879158766, 2303981.804829753, 2448254.22281334, 2425546.56793061, 2411257.638023202, 2329782.7933969544, 2304018.5564082996, 2380223.3181710555, 2488098.7141606193, 2344233.7604405703, 2343571.9102303917, 2439336.4485264653, 2408168.724268234, 2320672.546345086, 2317382.151579648, 2325785.089203533, 2433390.6654764405, 2447051.0479076984, 2448141.2860937025, 2342339.831322629, 2478543.8642270197, 2445368.3456623536, 2389948.1461152993, 2317860.1932654562, 2404441.2322634906, 2398711.169887807, 2418427.1766774016, 2385622.1676197997, 2404924.735859751, 2445781.620007498, 2485474.2946788548, 2390568.822675921, 2327598.1547689154, 2430011.4095602455, 2488458.7619889528, 2475016.6334794476, 2301570.9943477954, 2430489.261409391, 2477166.827925468, 2342242.204645408, 2382332.111829193, 2496637.32999075, 2473112.5130060753, 2319805.8408267745, 2404156.3976180414, 2494677.581705166, 2421205.02989461, 2460324.785606353, 2334347.341306391, 2301720.831998113, 2448450.2396201096, 2382416.470996437, 2398516.513719533, 2376221.8433221322, 2378353.1896389057, 2330104.0251338743, 2455092.7598587195, 2462375.555516972, 2401626.2155227857, 2421915.5515258186, 2492456.2701865365, 2374962.134440751, 2401702.1985295624, 2324236.98094601, 2418189.750224041, 2320858.659759011, 2473225.0491539403, 2412128.3985553198, 2392452.432121936, 2385067.4954245836, 2385138.713191026, 2419292.0117562967, 2305455.861994737, 2375003.6847488885, 2385068.217767961, 2428520.8385407026, 2382169.4764873157, 2366184.395535568, 2493723.509172342, 2448633.718794437, 2308092.689322625, 2362580.313499696, 2373569.3832675577, 2334930.3591935528, 2383210.736254359, 2419938.8542884346, 2398156.950914641, 2413712.498693742, 2356513.6550257728],
        5: [2466600.523443536, 2458627.8252260233, 2413183.4979181606, 2335868.7483604443, 2340439.4877140517, 2458101.888009551, 2334643.2241113693, 2429420.8017204544, 2406200.3576117815, 2380801.9427809343, 2322202.159583048, 2462353.226411653, 2327938.7463455494, 2455140.8316788985, 2432943.7311113025, 2394220.6604309343, 2480559.0954859927, 2317427.6745870593, 2478450.9605386844, 2441918.6571134753, 2477156.6758065335, 2460121.579668895, 2420507.414798898, 2399115.7199598425, 2462338.5944464984, 2465806.547722265, 2448944.3832224095, 2484261.9652273036, 2434198.693306466, 2318588.009375386, 2369016.6572123123, 2466570.536822303, 2476204.0389017393, 2349452.041459819, 2300784.2929066736, 2480480.55459013, 2472026.447003962, 2453275.116018651, 2366408.1064359453, 2374616.2891083416, 2458080.3362095673, 2428191.429083398, 2354684.197889408, 2476110.0558269667, 2386146.3567021987, 2341990.6106023295, 2490669.890029377, 2401203.387993573, 2337443.3283276637, 2318431.4094955376, 2397267.758301779, 2309729.9310783027, 2438998.275165117, 2411890.238568573, 2393627.082494824, 2472368.557909928, 2413871.9466216364, 2351162.9644032144, 2325771.31605152, 2437800.568925644, 2383597.052434989, 2429213.8270850973, 2448859.5244669947, 2457850.9837591434, 2396469.8365063705, 2438358.764709025, 2372759.048388088, 2384419.6757902196, 2441992.0218680827, 2322927.5121405087, 2318060.968428832, 2420354.1783874296, 2322183.305983426, 2429744.0604766137, 2416019.130363379, 2316336.661857958, 2491104.4675462125, 2325401.3222182035, 2484932.5394451376, 2410887.6818745616, 2408273.255584082, 2349943.9330445216, 2488724.17292381, 2412371.980941433, 2348205.102717551, 2495489.2621927266, 2325226.78316763, 2353704.0467534736, 2366980.945258692, 2318735.059846929, 2345592.781052382, 2377924.8455805685, 2496283.33769718, 2323387.2257165625, 2342583.0802998906, 2324949.7932513217, 2309979.35842767, 2328171.4062565817, 2316717.254669799, 2334746.3703563167],
        6: [2483476.6726216627, 2361181.737886482, 2425587.9790083882, 2432687.228449908, 2348107.9435658637, 2340054.0023746253, 2412152.414774779, 2349374.2451724536, 2427490.442045652, 2494377.8676687917, 2443025.701842288, 2334764.647027293, 2311223.2123464528, 2426847.4763744124, 2304268.7021260457, 2330191.9608245953, 2497910.5903246216, 2318700.912961082, 2383063.771420204, 2360307.5908771493, 2428777.146981995, 2347555.7978235325, 2385278.848366429, 2456600.8033008627, 2439310.260492037, 2354502.9322228227, 2336748.218079986, 2356832.3830528418, 2423417.913546223, 2497187.0490577016, 2424769.2652470344, 2371465.711460904, 2304455.8963727946, 2496342.0679078456, 2425907.695907808, 2435535.5610113842, 2356788.8001855463, 2485805.677348641, 2436448.196532572, 2368791.6320667025, 2498818.275038253, 2466043.61928514, 2325940.4848768027, 2337106.6053400245, 2378668.016989388, 2375724.931230951, 2418818.8114812034, 2380328.7747989614, 2454056.426977327, 2419406.8684819452, 2405372.6576847853, 2465308.525919478, 2397174.2285546465, 2463075.1268790653, 2403844.1931990893, 2435712.422593808, 2448990.776461418, 2484298.0899821245, 2436086.335730482, 2440263.5733853774, 2358772.7753769867, 2420622.140732935, 2485220.7488953467, 2347015.208821046, 2492429.0568458484, 2443148.4568588324, 2348071.2235949636, 2342707.629431604, 2383013.633818632, 2457887.52436786, 2361382.584464237, 2423888.1734425444, 2359305.236322458, 2357306.2015929106, 2334089.988797855, 2324279.2637408525, 2320360.1183778383, 2329866.521230951, 2307586.329272171, 2388618.159163421, 2462644.8642412676, 2454196.2225657967, 2362750.687990338, 2480612.3060938264, 2365643.6077471734, 2409840.330626262, 2462033.294585681, 2488864.201603779, 2406046.567132781, 2310284.1160658845, 2333904.241582453, 2423421.1729646465, 2412590.583765686, 2397746.181330962, 2492555.0456499946, 2452937.7473301524, 2491340.139189544, 2405226.0402457495, 2315175.839800236, 2324711.2319718697],
        7: [2335073.977524272, 2301734.4313380793, 2397514.1815279247, 2416373.9892892535, 2443486.7227341807, 2402792.872959917, 2445073.329417381, 2499627.395929284, 2320100.065212632, 2358769.0317157176, 2320373.376121724, 2403940.0996619132, 2482497.097467224, 2432628.1458030543, 2327006.5435405313, 2495267.812424998, 2355160.0594871715, 2498730.1088549374, 2481031.195877923, 2306398.5076594306, 2363169.7913550725, 2337759.4873363352, 2490226.149301231, 2458318.171837973, 2466848.9734723754, 2373243.176408156, 2339216.0468578585, 2411135.249480301, 2389612.333088779, 2375635.06294846, 2440257.2644880163, 2447351.1234160275, 2389824.5100479964, 2407717.797272355, 2393523.854404487, 2420091.773848412, 2423986.3886627164, 2328795.517103383, 2339297.744206003, 2377545.4317670474, 2398245.6989010177, 2382299.263853167, 2337575.940030627, 2426046.69833381, 2323907.851852557, 2483548.1154029067, 2343026.195044331, 2417368.5213428256, 2413148.1964187124, 2491890.8417698406, 2488690.70425196, 2385222.8598510073, 2317198.256358608, 2445921.076981658, 2306297.807616649, 2305348.470939155, 2445148.7961236164, 2373226.3502850286, 2492844.903456232, 2454117.543527515, 2453082.3933080463, 2488217.5685086483, 2482761.206918116, 2432475.03440838, 2487298.992491387, 2348791.540617074, 2360188.9301838735, 2364334.00106606, 2460351.9461995144, 2477592.963736994, 2391485.360567594, 2479358.3401226727, 2468566.411588188, 2404375.4109886843, 2356907.8534423164, 2403214.3892815923, 2432520.3866934883, 2346226.6664367514, 2405601.819577436, 2423005.994098196, 2400992.3662929907, 2411361.910325948, 2432536.6703094942, 2444788.808283845, 2335245.5551946377, 2373738.403951279, 2461618.3347065886, 2446221.749144093, 2371656.822590794, 2413669.4843428554, 2475539.928373483, 2459824.89788868, 2433875.885831495, 2361397.345541678, 2446134.582739708, 2304039.846368988, 2477213.112506634, 2325945.571818585, 2392162.239015958, 2490112.1836209744],
        8: [2333921.1917630774, 2310265.7555219834, 2304526.9867733642, 2440346.652264143, 2443333.655444177, 2316556.7150348057, 2423152.541124876, 2358894.60456563, 2447342.1112528886, 2394610.8476996305, 2427203.8632576773, 2494378.933175331, 2312374.1117130294, 2351910.721805029, 2493141.9330299944, 2391509.5043716026, 2339395.585804214, 2396360.278761232, 2482089.110367683, 2391987.7785509336, 2466052.5655174823, 2463679.088140593, 2493385.580063267, 2386145.5233263397, 2486187.6962615224, 2373994.3183221663, 2369609.375905692, 2482965.2736441917, 2464297.0839158427, 2340659.107857916, 2329933.0176534806, 2327259.1628287607, 2339914.9672131897, 2333518.693734251, 2323466.31048766, 2361873.3510783855, 2396315.0853327163, 2319597.0633276836, 2429840.615604865, 2305373.926398921, 2329936.09078376, 2354053.5292184874, 2309580.8441466726, 2401828.5351203103, 2383912.863098296, 2341947.3263088865, 2411293.2584555815, 2306480.010343741, 2394720.6398942596, 2319325.147423712, 2466885.103927626, 2456800.8344668327, 2367760.8002062575, 2465516.816487632, 2355760.5059843166, 2337351.861195692, 2302142.8531523393, 2450184.2728374787, 2458253.3815178224, 2424924.406380248, 2388468.5016430835, 2410851.9009835008, 2355417.060126991, 2452806.5774668534, 2430005.015184864, 2343681.351903421, 2390484.979932003, 2468362.714004625, 2460801.802864667, 2463315.722963526, 2369469.564838675, 2449461.067291847, 2433630.7348531587, 2340713.962007685, 2300045.318964699, 2387269.0866970676, 2343476.079582016, 2424978.314770199, 2360052.708712951, 2344593.8683117162, 2479184.886530278, 2357327.830795431, 2446986.575573443, 2327609.763427042, 2309115.1684787367, 2354961.707574681, 2488286.0148469284, 2412079.519127546, 2301603.1887844563, 2367152.996903528, 2325971.3376756213, 2333438.8921329416, 2357746.4127512337, 2411296.9952021018, 2349534.0727429027, 2454469.5476446473, 2373987.675961155, 2310885.2382250656, 2440906.7920317673, 2359866.7141264654],
        9: [2455527.7619255516, 2341025.7644104683, 2335308.269554337, 2446703.2970494325, 2395521.757978203, 2418121.320435597, 2367610.2214083276, 2416444.865367086, 2442337.0724248993, 2356754.0608242596, 2440009.115259376, 2490061.312054216, 2452733.273975494, 2486274.274268238, 2350454.6618151264, 2485431.311002062, 2312469.072243762, 2312264.7194036325, 2311489.0798713765, 2428344.8463391806, 2301214.4210529313, 2302753.557953978, 2428768.1092301286, 2410920.0524595333, 2460243.0855496344, 2468164.4216273916, 2396385.7906650803, 2459414.006810843, 2349130.796650919, 2424772.700602248, 2464650.940104863, 2308026.153785933, 2437129.944099699, 2300885.0645145196, 2405094.2060884875, 2340978.6210492845, 2438597.6191113144, 2401559.6684656087, 2379025.9256533706, 2366997.9715270656, 2339112.765853351, 2438918.012110132, 2361676.0706944363, 2404681.2115299944, 2308796.454373802, 2344804.773317412, 2411526.820649701, 2446453.5960970554, 2358004.8199706315, 2394029.6178234504, 2312117.951551142, 2396395.054179069, 2451571.5307680843, 2420037.8336213646, 2457510.917488701, 2490440.4157781475, 2421981.7601989005, 2419475.1035056664, 2362323.5418578424, 2417002.5780946184, 2489473.1876096805, 2464235.41014769, 2498771.3371549766, 2388200.7908930043, 2492437.631437402, 2460517.671050836, 2351890.300514188, 2477119.7312929514, 2425678.3533962057, 2405098.449881544],
        10: [2458146.4596809656, 2361706.727672274, 2394197.9466209724, 2493118.84988195, 2441728.332370224, 2424250.582860345, 2446742.5880900105, 2492136.385655863, 2450581.8773199026, 2484855.6892934907, 2459659.5136449947, 2426992.92436475, 2435637.873756387, 2379253.9646870387, 2437203.6485208324, 2302033.090731422, 2431698.8400367238, 2414524.8495237804, 2336949.089644467, 2306902.939975729, 2438790.8904750366, 2387386.61199764, 2307320.2826028015, 2338996.82004818, 2303677.4109203154, 2361688.021640309, 2304428.2737301746, 2459175.5736680864, 2391413.407892533, 2422622.3885573903, 2465249.805310513],
        11: [2367608.8041553698, 2387736.094787754, 2367873.71419649, 2488762.0795439137, 2302172.5324369622, 2454579.5751719517, 2471551.289319142, 2422917.8965887637, 2308149.5322073447, 2439771.274976826, 2364350.430332763, 2422551.7787434277],
        12: [2465219.8538686084, 2422622.762471539, 2387811.1374316863, 2430308.472789573, 2469028.5666222456, 2469760.7486021314, 2469628.1077070087],
    },
    [RowsType._13]: {
        0: [2564832.8162385165],
        1: [2664125.445461487, 2557521.4501334787, 2532684.8236010745, 2593084.967611609],
        2: [2549879.609407055, 2527968.1524911723, 2652458.276885437, 2680381.737988573, 2579057.4227471156, 2629531.5074159373, 2578761.4953471865, 2501711.5960867964, 2503382.0367657323],
        3: [2612796.270243713, 2654476.9234571233, 2507468.207565416, 2515716.441117984, 2624697.975355171, 2585341.4180972325, 2517528.2227504337, 2593660.790557582, 2502415.7690445436, 2683851.5654333457, 2616728.4182056743, 2646323.500567033, 2520102.965906631, 2507967.007139926, 2559136.229981314, 2556610.050482693, 2630863.816015103, 2502135.2582011153, 2667880.8420179132, 2510977.927110487, 2588600.954585258, 2542281.7856674637, 2686509.160479905, 2539677.275547889, 2660560.4613595083, 2565326.8430223637, 2660774.894325022, 2514743.61636904, 2624247.7137510045, 2564856.1963672214, 2592025.2618638985, 2599292.6456710324, 2526683.682934429],
        4: [2652819.6146131237, 2583706.277387937, 2679504.461024005, 2554844.3804618698, 2516523.5561887696, 2508621.1655726735, 2653971.708012667, 2556381.9896846493, 2581637.173957381, 2650990.125325292, 2631889.664907938, 2533628.7761820573, 2655504.0473187827, 2507824.9341789465, 2555393.0658193114, 2609492.445107183, 2587583.166068522, 2524559.064316663, 2500289.7667698245, 2510515.076963463, 2651983.777738626, 2510225.2090083044, 2655815.1111074737, 2650455.03619412, 2603913.721416212, 2500534.7182671325, 2613210.5753493872, 2550814.6268965183, 2570472.6031512045, 2530214.169376857, 2579116.2830612864, 2597289.456990024, 2566170.2661678754, 2657061.805817019, 2580321.911066331, 2573830.4639129597, 2636496.9761672267, 2647296.295340338, 2565138.794272425, 2624196.1519486913, 2541037.3463243716, 2502713.258712494, 2629631.237155329, 2501174.3608077024, 2647562.7678567674, 2656087.9840991255, 2529096.127881584, 2595192.064685639, 2595525.4540281296, 2537551.7394260387, 2628973.064681498, 2629657.448894019, 2684793.342725362, 2563761.340649412, 2533967.407779739, 2687133.5294848927, 2561257.5623347475, 2616493.54456646, 2557925.649109524, 2686388.8344710516, 2665032.411197873, 2661757.7039060877, 2592971.1648719665, 2623925.24117255, 2661160.0236365814, 2642980.7965288977, 2595479.8737099525, 2502015.629371866, 2680943.214341144, 2589542.8750802195, 2682623.0489628557, 2575013.30334066, 2502839.602037728, 2521525.5186783317, 2632088.552993254],
        5: [2631537.4906611466, 2551628.136945842, 2589001.750545649, 2543982.4506432, 2637542.0697539495, 2667468.240359375, 2665182.5637965063, 2697129.683921254, 2607189.874464837, 2616045.2114912183, 2590456.372468045, 2541347.590678968, 2587740.968580695, 2576212.5623783534, 2652147.2761618127, 2585102.6408168077, 2592066.734744798, 2578819.619210837, 2509399.7771098553, 2532931.3746114178, 2650498.569701792, 2609562.406240364, 2685240.349925864, 2504629.2758333636, 2501950.9188114097, 2526000.06771908, 2653804.487104708, 2656089.140759437, 2576035.7832833556, 2515661.801558168, 2569137.0049286955, 2608809.1529704104, 2524702.4185303017, 2593532.956663357, 2615702.817208357, 2507323.850406489, 2579032.306438249, 2594279.171753799, 2587023.818824166, 2617926.4129593796, 2545924.6817164724, 2587162.8402401432, 2554991.395512897, 2582629.2247545035, 2540557.428197208, 2522738.78213964, 2559376.996467196, 2524266.899909264, 2500844.950500792, 2683708.747551522, 2588643.087877645, 2679583.532251614, 2678245.083783592, 2502915.058744244, 2521242.9643003843, 2576480.72312967, 2554264.736355067, 2679734.3468256635, 2660977.18605202, 2667722.4106892175, 2538511.8279900914, 2670234.8460329743, 2595021.1881574555, 2508010.4795722924, 2675036.3144954736, 2595258.400045512, 2617402.0660057217, 2505142.1242362657, 2616254.4506578706, 2582482.711853765, 2517919.468915386, 2575596.5980534176, 2570115.8954753233, 2569382.877092654, 2565445.896388015, 2584150.8707692497, 2601855.8273611977, 2507963.8852056335, 2584464.925452412, 2607353.738166547, 2676147.03161339, 2548148.7268265425, 2658544.47121933, 2579978.2176630767, 2644395.9895302174, 2672512.8954905765, 2599020.4033611333, 2670103.9236721294, 2655670.591785401, 2575583.6981912246, 2596617.642213976, 2585340.998426429, 2654166.5117582735, 2639976.3907060595, 2681801.835246529, 2550866.321871263, 2525721.7753880597, 2512879.0478991373, 2613159.458917939, 2672181.108093543],
        6: [2585676.570001557, 2601056.341692839, 2683568.7651127195, 2657705.0898475056, 2688132.2321396386, 2569130.9461129275, 2583936.0867378064, 2679949.8278968493, 2681053.753014056, 2614969.80101154, 2687437.8537937305, 2562937.812184131, 2575698.2539154934, 2634057.0339515726, 2529624.6711862623, 2508559.390782393, 2623689.224546023, 2675716.283816285, 2603495.037752105, 2588847.4436373743, 2531977.3788180347, 2555432.99384417, 2647326.0342314886, 2513371.5029151374, 2682603.6525184396, 2654207.035290931, 2605112.957877267, 2573480.2840803047, 2535514.5886409516, 2563877.0663900864, 2616638.868826389, 2515700.216304741, 2686162.6545053604, 2647489.8291535387, 2659674.8354155715, 2598169.396604203, 2504862.4534165165, 2575125.835639743, 2657127.9250418255, 2538511.4860077333, 2595762.571371759, 2581412.540167402, 2559457.475077942, 2670643.206947901, 2659170.898928089, 2631071.5401024446, 2505660.878523292, 2573619.540969766, 2599111.903028244, 2568685.6375199477, 2694686.5899968627, 2638995.908990525, 2635685.1534764506, 2505225.8407615316, 2597009.860480612, 2645597.2682706704, 2647270.86275726, 2531726.4213407882, 2678239.564275664, 2526553.508445315, 2687362.748553747, 2576365.393500374, 2661982.50392707, 2672790.6816417687, 2577086.7471549185, 2526113.3951168344, 2664935.2157053556, 2500464.0940121105, 2648058.9067972563, 2691048.6386468797, 2502720.086598668, 2640442.491935189, 2592112.7290556086, 2505234.428285138, 2618725.550453405, 2681542.3879231173, 2584246.065298011, 2587450.0588572705, 2677385.8023573654, 2565895.0739044216, 2623305.7089810325, 2589259.04941402, 2512495.86682915, 2539631.610314337, 2569719.1093760533, 2617163.9339677365, 2683904.8966336846, 2615688.791899131, 2676106.0789986467, 2648392.967054859, 2635901.1364224227, 2597930.339037745, 2538213.935061579, 2666991.4371130136, 2615894.976668976, 2585656.0318142893, 2591729.9479264356, 2549521.8996643596, 2605716.977612025, 2695261.0257243784],
        7: [2591669.7090943283, 2602401.713007084, 2529005.786109668, 2668215.956665392, 2584182.9512291555, 2542024.0330064325, 2538638.6759147216, 2658065.6079066177, 2678835.6543457285, 2601739.1229897398, 2625699.18307283, 2688227.1645731074, 2573808.631441112, 2651229.8152443185, 2655516.207232193, 2601733.978490695, 2517868.099996488, 2543499.2145772316, 2500360.0113925016, 2651867.6102592456, 2586849.7480266914, 2645341.585164432, 2682633.2643096317, 2548400.630467794, 2540629.9946677187, 2571019.9296734645, 2692402.6132081263, 2663793.148875788, 2631510.725505014, 2518737.419886078, 2693775.194399386, 2599264.661099157, 2562123.1380319404, 2605956.65385518, 2551535.662150913, 2650445.0446598395, 2559294.551137097, 2677838.127387204, 2637124.4193768175, 2637578.9110182375, 2691122.631053396, 2591754.1931441734, 2511914.3660303005, 2516527.1612100834, 2699692.4064585823, 2631825.445360422, 2528196.8850553553, 2535600.7009491, 2647477.5283484748, 2534617.942463427, 2590520.898849027, 2606777.4643472615, 2601110.734396043, 2635066.4803586244, 2596967.9584091, 2602332.4610850923, 2626083.598146632, 2676458.7237648815, 2624667.4016850623, 2597209.84225307, 2698943.2899012878, 2562982.8115134235, 2633232.884699887, 2508812.5526320296, 2509295.691030689, 2552830.763824419, 2508775.7857132168, 2519548.173000609, 2582523.2780190175, 2663345.3938763756, 2514645.330039508, 2521143.969092305, 2690625.705487042, 2630195.8623741628, 2674217.6008685613, 2513101.3695202814, 2687877.989242451, 2539976.1842147, 2661509.4146350985, 2570999.9772142214, 2659982.055211072, 2515500.0032127225, 2694486.3317068373, 2658539.1286950717, 2626381.903271872, 2673532.076847683, 2608110.1027498543, 2509001.3383043767, 2522902.4498377256, 2513565.9666017424, 2675775.0534664476, 2615182.597258906, 2530309.21879284, 2506002.5446846266, 2609817.268159421, 2679778.72240473, 2513719.07134752, 2512721.9859632286, 2684038.0912537533, 2653649.4120574035],
        8: [2571021.2172080344, 2537071.27139514, 2593563.6703723953, 2521821.315726653, 2578524.5570420334, 2699220.138606572, 2598336.2401842964, 2697306.142832982, 2545324.8780741794, 2611638.331098587, 2659473.668625516, 2616557.148569885, 2679855.213448732, 2591488.0661317897, 2682651.6799096977, 2656282.7805784782, 2535848.1379791657, 2634413.48933585, 2514540.522054957, 2504402.133381691, 2504904.3375762585, 2515221.6874018074, 2546982.0110168937, 2640058.54691966, 2604825.898694476, 2507535.108265504, 2662496.940447422, 2593134.5990416766, 2609908.1708434685, 2551622.1185897244, 2526346.0951084257, 2530625.21170375, 2597306.4022663413, 2658967.632081154, 2647597.8570158426, 2540632.592706257, 2511477.246738759, 2606089.900797385, 2546801.0850221417, 2568108.282846051, 2615003.075838139, 2691432.091665281, 2566132.1310744346, 2629298.710230057, 2653052.9928093217, 2580417.0989455604, 2608811.0206816806, 2640189.347120677, 2643598.8738447856, 2544874.978559443, 2642731.227241789, 2532763.890844433, 2534971.754834721, 2629991.751501549, 2612073.5439996542, 2574445.075635428, 2695781.86254685, 2636859.5985881076, 2614959.0287377965, 2601092.061078079, 2600163.9729130194, 2694174.737423139, 2663708.0129166087, 2512232.1301100245, 2659244.492548169, 2569215.4653188023, 2517481.368511257, 2599277.91327545, 2690644.6255917912, 2546159.9328692495, 2519982.8372936486, 2683115.1053457656, 2543477.199597776, 2544357.826848461, 2653274.934171486, 2579048.194826126, 2506973.5719035384, 2580297.4056168604, 2697968.576319304, 2650670.7723971675, 2613730.493556093, 2684365.2170717367, 2559918.7987923143, 2519579.693383653, 2515900.6969521535, 2689531.2883489653, 2688748.67743899, 2686925.7513124985, 2623947.3730757874, 2628931.7388136606, 2686703.449619122, 2583428.8516873023, 2554842.5448232815, 2546202.0396235078, 2585108.3616339914, 2532105.5734776785, 2560241.770152811, 2623321.500507246, 2648325.0603100485, 2588207.7875655987],
        9: [2647921.488448289, 2621111.180425861, 2570623.724004737, 2565797.9675074536, 2670206.1129015163, 2669302.5876500285, 2589589.5250306763, 2662064.707257324, 2546799.926190389, 2515117.254635223, 2661316.6681374274, 2617326.1851663743, 2636767.6285107974, 2654961.503770481, 2568139.4842255414, 2657025.169016838, 2546196.9871242614, 2636451.2391083864, 2639541.7549761133, 2627257.8954297425, 2530623.9986653444, 2555954.3961772043, 2605067.7533465736, 2535522.52939613, 2592412.5991049204, 2534125.5575864906, 2698401.9222000833, 2524854.8193880375, 2573957.420155741, 2567581.355565351, 2575885.9149338724, 2688077.9019876225, 2693208.2225723723, 2573455.87057149, 2656604.262787823, 2666888.3027529013, 2523229.095812869, 2666988.018268814, 2597959.817395361, 2543492.6701035015, 2529390.4362971885, 2627057.749969916, 2585343.56328476, 2612698.539911653, 2537431.337017447, 2645090.4310201197, 2634606.2536612796, 2539094.1568527506, 2531653.970841138, 2593845.5623817216, 2692280.9297238886, 2523536.1124585327, 2621163.2102854806, 2672952.657501055, 2662819.7639716007, 2544844.197478635, 2600891.1623382424, 2596923.762951147, 2616682.8876417517, 2517915.6125790295, 2639799.2778451764, 2576951.564577843, 2598910.3713904177, 2639296.804277677, 2602458.4518712577, 2675953.862606362, 2689784.430671548, 2568834.9106648234, 2643257.644914687, 2675144.9965746305, 2557535.4296419434, 2655981.3651535814, 2506707.802095524, 2611367.3852177374, 2596804.161300147],
        10: [2698340.791371204, 2674756.782573968, 2646681.449621794, 2669764.4371737065, 2567526.3782326872, 2679738.301148459, 2660812.739964865, 2634481.1207701075, 2518673.3122896254, 2629888.0412606183, 2698413.1128936643, 2643883.565609039, 2547087.9603907773, 2672996.493785975, 2647877.7321717716, 2508944.9389039758, 2617680.4578844523, 2575872.732415619, 2699004.027690292, 2669029.993391528, 2616286.9492048332, 2612042.935717283, 2609503.1334205307, 2692242.0807643356, 2692981.915525295, 2576267.6422235006, 2601181.6897466597, 2639204.882837616, 2591849.335419775, 2592505.7780887643, 2671406.3528373153, 2546805.3353489637, 2659797.0487105157, 2639652.789149162, 2590831.205460925, 2516518.18044787, 2539658.5278991843, 2689415.4966038377, 2698514.9318637075, 2610085.6754140295, 2618796.0374479312, 2660146.924344707, 2671437.9772239663, 2624363.8951227334, 2512283.628792424, 2597486.622469511],
        11: [2551588.1246571224, 2529228.3662559697, 2698812.0205579777, 2610786.8540829266, 2510996.971751234, 2693103.6478715544, 2627538.5040247994, 2573798.0164384353, 2609329.9041367755, 2646612.8253044155, 2691607.3211552934, 2570148.8615156612],
        12: [2637827.117517618, 2516191.920501422, 2698420.4174398207, 2549818.67759299],
        13: [2637219.52852953, 2519472.0795251383],
    },
    [RowsType._14]: {
        0: [2690510.017984564, 2816720.1655583223],
        1: [2831214.6483928445, 2681637.5653005783],
        2: [2783099.6944322623, 2812332.5253937943, 2770276.8469502297, 2835186.1607186776, 2674537.9171270644, 2736816.374377845, 2805566.096802615, 2738369.560723595, 2683498.8104078933, 2715449.4551659394, 2741828.6891384684, 2705076.0048475666],
        3: [2694152.869162765, 2804740.0051206937, 2759704.0460779388, 2835138.058502259, 2737166.5560451737, 2803350.9072774, 2758883.9296784373, 2775783.0727364016, 2680806.2670674706, 2681128.2860160903, 2765969.2815658282, 2763555.6204222087, 2689380.5473238244, 2682891.597331319, 2775633.325921773, 2833182.0018980494, 2739889.6181982546, 2844215.2524154563, 2685136.216780799, 2683340.207015847, 2674490.6570442026, 2683071.13761994, 2838077.2873861217, 2779941.8475245186, 2787029.1898047957, 2758734.761161123, 2812299.344245129, 2780224.476711],
        4: [2705195.8454499408, 2704693.7853557514, 2682480.061352668, 2720650.987387853, 2653301.69738746, 2729945.5703944033, 2775182.4664277397, 2677398.696177584, 2784033.61109318, 2706096.6169322864, 2784310.3678488308, 2749496.7050884305, 2661518.9813593496, 2662636.105627291, 2701983.708709484, 2758726.714659295, 2757974.7659999426, 2674544.93549563, 2659617.1054886277, 2772215.215550165, 2847812.943654483, 2792867.0774238766, 2693298.403756071, 2769684.7722740523, 2808435.7547701523, 2756075.618273603, 2748568.324267151, 2740915.489495411, 2731022.66552857, 2665693.3960098275, 2847851.6098250896],
        5: [2823864.3333600303, 2782204.052503761, 2682725.9934583693, 2832912.965557371, 2825758.280033088, 2783794.580811439, 2821229.504800529, 2849087.1590966894, 2710995.518214632, 2803740.531506655, 2683371.567703993, 2679473.2386107035, 2705744.224405658, 2673020.428981444, 2722438.487026952, 2834517.3971004863, 2800198.218905868, 2803867.4331334746, 2773941.7234153855, 2750690.6378491954, 2731784.3759733625, 2787342.8682925147, 2652224.044870792, 2746933.1249205167, 2763770.136271727, 2686425.8329644427, 2735947.1950736535, 2698132.807646299, 2752790.332054207, 2735836.5182281653, 2688129.0113152047, 2709444.7214981387, 2719260.3826398086, 2743759.1545397188, 2705046.649527615, 2682465.747500344, 2697966.61600825, 2760214.861451488, 2714676.9473480764, 2734353.684621729, 2804365.3680573213, 2787273.113184294, 2705748.446751275, 2768996.9369701147, 2784147.9051204505, 2675811.8754934855, 2678229.8554060073, 2703620.9176842915, 2660075.760896159, 2688119.514509085, 2661444.3858633414, 2717619.418522668, 2825328.483141138, 2821500.741297043, 2701239.727433599, 2847839.752359131, 2743395.7296282817, 2792494.2870606924, 2726122.3351660203, 2848474.0885778223, 2671756.72831285, 2674177.0018780846, 2782689.616431451, 2718994.60315922, 2750387.1054920144, 2669982.881534006, 2705321.1668996015, 2779185.634396283, 2848188.2218760177, 2682679.7109148116],
        6: [2821146.5026343097, 2821413.6373679126, 2808507.4830481964, 2755565.859857171, 2678588.7583716516, 2706071.734152196, 2840455.0341582103, 2667526.041657981, 2803846.796831579, 2805641.8974065855, 2710284.8314211094, 2762280.1798367756, 2787518.97981688, 2724959.788019329, 2689986.009082346, 2810567.749895575, 2750574.82448656, 2687595.845935833, 2745249.860991868, 2738488.032100524, 2737645.3353556963, 2657179.3725780314, 2796018.06990057, 2773694.682238434, 2826842.9813702023, 2724346.2589798095, 2825854.545977573, 2800620.2322344165, 2779382.6907753064, 2816637.3405099036, 2685921.957964866, 2835475.412291981, 2809952.4174068994, 2821549.4273677273, 2847233.560711453, 2828987.5412793383, 2764646.5572193735, 2833986.364671429, 2809623.9846796338, 2709845.6664616135, 2720011.3009429667, 2675495.18613306, 2729024.7667173254, 2713428.689012164, 2786454.2361071836, 2682902.7110643983, 2803892.0206613587, 2815014.4878064883, 2672614.036918362, 2819762.8336335975, 2788830.2052789954, 2832708.907957802, 2681140.9709542454, 2678547.509712604, 2849441.2281081113, 2697569.1605698783, 2771170.488912617, 2833689.7384760776, 2661065.0829272144, 2800009.0074036377, 2754052.199729668, 2780926.9048165767, 2754362.495183197, 2827330.9357761163, 2685623.475116851, 2825944.7969900565, 2793164.6730554644, 2659693.236619521, 2736646.0525146425, 2687483.43098554, 2698882.6851172764, 2735704.761612549, 2652212.7575038755, 2744150.034162886, 2717886.140649403, 2759899.003253809, 2818078.942332482, 2708191.617811271, 2769556.232699727, 2677811.5291943774, 2780902.5238773986],
        7: [2807860.1985687804, 2841025.6947504776, 2820395.893817344, 2806680.5921357265, 2678018.031310001, 2835205.264306291, 2782317.9397912766, 2788007.252553496, 2712893.791729957, 2815312.6517642913, 2665155.612574766, 2715405.9521346623, 2762880.952588519, 2715002.292008147, 2791329.8839835525, 2827061.049074652, 2686444.3889544434, 2836419.0794901866, 2757747.116756615, 2764778.01023777, 2757882.8918953273, 2849928.916296214, 2776350.723837614, 2685880.4091886403, 2725760.568273576, 2671331.01530505, 2699480.3259151415, 2701669.397933143, 2760426.6395610007, 2716519.588724278, 2708499.986428245, 2779013.7679077885, 2693747.414984944, 2757327.6051819692, 2846062.8797791344, 2816262.822667813, 2731202.619751219, 2677682.37975749, 2759146.7972591696, 2827723.92598319, 2795958.5266296035, 2716729.694806329, 2734704.8508821027, 2702156.533228463, 2784721.2261965023, 2801302.6838482865, 2838396.3911531297, 2684557.241860149, 2726868.7849838464, 2770663.524973039, 2770775.8729173266, 2758482.4867489603, 2758581.15361142, 2770159.165478316, 2764591.3575540883, 2740133.5502615734, 2661501.6267346437, 2777888.1127954135, 2824493.3925362187, 2706163.560892708, 2702041.4319854407, 2772835.400761021, 2704282.4652726655, 2827209.1033938318, 2732037.1950800875, 2780923.7900296766, 2779758.749224993, 2659857.0577674685, 2733377.284002611, 2828330.9176123645, 2691528.558608413, 2654950.33131084, 2786538.2376316716, 2844620.7071353267, 2708323.8719060025, 2665548.425966962, 2656972.9456742355, 2749952.953897826, 2822914.0903570885, 2808018.781740879, 2672033.349378226, 2753883.3911012593, 2796582.4063922837, 2798833.9987298753, 2769829.6968554426, 2826925.0770874238, 2833537.8283512164, 2710357.007379986, 2759637.5834782794, 2786519.912053582, 2671878.511321718, 2704086.6968928454, 2837021.0222177985, 2708948.181345967, 2782092.8027019436, 2837071.1130591105, 2704708.873048091, 2739475.1551909666, 2678172.650783948, 2789329.195431741],
        8: [2825290.257665017, 2713837.9288610686, 2807690.5691014775, 2747852.1259948234, 2791147.0680037336, 2652539.0239976794, 2834336.068236299, 2748931.5150751895, 2778450.78948926, 2839146.8348353333, 2671966.5686397506, 2846546.3096972285, 2795541.7624326707, 2839878.7802775796, 2823119.412903536, 2659339.216883695, 2770183.3979585273, 2830615.4280641982, 2712428.9429448834, 2734220.0373071576, 2760983.315933432, 2809213.514648312, 2828822.961293083, 2662998.35978797, 2784974.0992843625, 2667554.7078045765, 2686069.193514395, 2842849.8922387627, 2830378.8450366403, 2700402.2272466007, 2681989.302062979, 2703007.3913062047, 2702046.055132454, 2717851.133257204, 2844987.582557173, 2768977.858396907, 2695764.1120460504, 2806087.440950892, 2724497.600986112, 2840312.843627149, 2814635.408071183, 2786501.2497323495, 2782940.9595587333, 2725807.9165257006, 2848099.3842954594, 2846438.4361788835, 2763653.6112035913, 2745720.218025239, 2788450.397121487, 2666653.48301875, 2672259.862195698, 2841053.5392772094, 2782228.5784464004, 2819114.7088982672, 2753055.4122673566, 2757122.437894207, 2660520.308602439, 2847040.0128459223, 2824522.6724968245, 2688596.586283065, 2683774.346188842, 2748493.9712090567, 2705203.1701481864, 2835478.8674274064, 2792508.8107248177, 2724234.2112490893, 2788802.1131501123, 2737696.202547325, 2659218.7459380524, 2779885.95887172, 2747015.525639792, 2676716.3883799696, 2779181.9167681728, 2708996.7620937414, 2824953.378949628, 2792893.4308495037, 2828802.6998993983, 2735946.673160971, 2818630.6986809135, 2700652.209356171, 2678995.8950202786, 2772015.122305023, 2789860.29781466, 2702078.601828007, 2845995.0379047655, 2732718.3142299857, 2744723.796196956, 2721403.3545600125, 2675211.4420379167, 2842795.350882858, 2757100.646430279, 2733891.7425634456, 2765006.6013316223, 2723521.672688841, 2846979.172888634, 2732729.2468575784, 2776732.2414988996, 2839498.5029534562, 2725956.258576864, 2813223.3045268618],
        9: [2716608.995221264, 2794497.8367984258, 2717555.491051962, 2845454.8175187297, 2671745.0144702126, 2843683.5621974915, 2772192.390346707, 2742118.3538812045, 2791248.5543815484, 2660824.3470604625, 2652971.203671459, 2753645.8548906706, 2841286.289872203, 2755234.5809634174, 2739443.605253946, 2733290.326353441, 2660417.696102395, 2712339.4541210188, 2698798.69675955, 2839848.3124660966, 2823689.9611414946, 2758051.2446324495, 2806225.3549154815, 2795493.5332372775, 2661304.9293102524, 2771470.4302915544, 2662123.638875138, 2783144.48061118, 2704319.859011324, 2779209.0843716436, 2743147.751061003, 2753239.8503714018, 2807182.3185859425, 2663028.9689944517, 2713050.941261618, 2701543.5020843646, 2711747.8557054987, 2720540.628477313, 2671366.5402840627, 2801425.2515134183, 2650436.7560016303, 2835056.3908393583, 2651625.613126844, 2708215.561538331, 2651500.6220886963, 2833399.710692084, 2828255.468359487, 2827808.8029476856, 2662664.1822487987, 2702912.917828356, 2722113.7829651344, 2833434.213974043, 2658637.5361926, 2655398.3878839687, 2694647.5240713377, 2667265.8877271023, 2739308.5984954326, 2656720.794267402, 2728081.128443292, 2767041.9329403713, 2652671.7605307656, 2769028.6542415437, 2728517.4823848507, 2779536.6307053966, 2696828.7137339707, 2817257.0478654224, 2669586.6735709393, 2717715.1701907413, 2751778.26605508, 2789247.8027362297, 2824158.5026860605, 2714454.236247954, 2677832.292245083, 2766442.37483827, 2792190.2942015105, 2800355.3101710095, 2733389.8091604225, 2826071.0781227457, 2814939.82954887, 2786761.9801114337, 2790139.866742253, 2781492.953918904, 2650129.5561845335, 2664291.1389530245, 2819978.6542717703, 2780257.134399448, 2747636.8543156316, 2661789.7591960365, 2703435.7092950433, 2726266.620838344, 2802396.1517437636, 2784886.3988723406, 2849742.1581261833],
        10: [2771293.713897166, 2748035.270812627, 2760810.4581627147, 2735277.500855039, 2667752.142405267, 2750293.3829283062, 2789406.209256265, 2807252.1843619556, 2653551.801004313, 2668182.862127617, 2846192.486626629, 2712578.4054163746, 2754637.3370363456, 2828464.753340388, 2668262.978688332, 2662448.1774258898, 2787969.7167301667, 2750258.271995787, 2657698.5320225693, 2815607.1129368576, 2787873.622802842, 2727499.653778518, 2811570.7074956344, 2752960.216746958, 2831627.83836519, 2684465.95003846, 2778750.5368886967, 2711120.0428095963, 2667372.669996023, 2782774.9347638185, 2698125.9744404186, 2831485.580806599, 2816593.367723234, 2690130.8251134274, 2666373.1585263917, 2698858.651214647, 2664337.4503810215, 2819239.679007311, 2803506.4965258264, 2823268.1660190453, 2706373.627048416, 2663625.6826148117, 2667116.195140452, 2798564.4594505443, 2656478.1510929144, 2789962.5895456644, 2709003.3807470375, 2656507.945586891, 2672759.6723973122],
        11: [2790053.6236085333, 2669135.51617275, 2801341.2040340444, 2693666.2799251457, 2773005.9999967697, 2825299.18345224, 2824190.0653440086, 2797053.8870640006, 2757129.5226729433, 2800732.7285507033, 2840142.43121239, 2800414.2274985844, 2663198.288054151, 2721096.2662591627, 2818203.2190171746, 2716722.331596268, 2802094.0667398954, 2756247.231893612, 2698199.4245715747, 2782901.5935039297, 2836326.0391327543, 2814499.5210399344, 2839372.9984395937, 2657127.981411963, 2662305.84400487, 2785379.3511053477],
        12: [2837570.197723314, 2836342.430676608, 2715535.967680355, 2666794.6719336235, 2810048.3510678113],
        13: [2829184.253475985, 2786146.1362035745, 2827975.362439842, 2801174.6832574, 2754029.0726926266],
        14: [2825175.337503278, 2698368.075259809],
    },
    [RowsType._15]: {
        0: [2922799.094563985],
        1: [2935761.6223381325, 2915072.245096695, 2930164.6808323683],
        2: [2924218.000939432, 3043824.8095645467, 2854232.791334407],
        3: [2922116.070522124, 2916631.4407825526, 2860443.011995074, 3005976.5876248404, 2916688.617230332, 2934019.6991820526, 3041411.1425917563, 3036235.5632909047, 2873707.3976886193, 3008579.1407213206, 2905532.5642327387, 2856779.892956253, 2949977.2148355753, 3043297.8003835646, 2983993.8757013716, 2919118.9982829513, 2902624.317185986, 2937864.880570021, 3039178.066777633],
        4: [2900177.5703771017, 2938698.014384068, 2937726.390915956, 2875770.6375340833, 2917430.606151319, 2868329.3479353436, 2966455.992319901, 3036820.4850891973, 2968711.873976816, 2959874.4380607, 2955009.397133336, 3031185.4282067004, 3043775.424862288, 2972731.005552694, 2948407.212118231, 2943964.16922535, 2984954.5525994375, 3022936.471092414, 2938512.6402244763, 2875544.8337125485, 2988844.582658757, 2854510.0464213537, 2954966.5466708723, 2882663.7151554637, 2869161.006742619, 2997033.5694140443, 2863616.7966099675, 2872975.591020845, 2921064.6079381024],
        5: [3021219.748656008, 2884982.6178050223, 3037145.2416431364, 2954163.024714031, 2943722.6660772385, 2954567.1184972273, 2993093.9013238656, 2983644.9704778357, 2906482.9721069965, 2882944.872639008, 3017133.55418529, 2880504.8832514435, 2934855.1087309117, 2958215.516420703, 2890995.458627738, 2855649.6790576996, 2870212.3819530234, 2875672.699169996, 2896133.02981871, 2856924.1913255546, 2882264.641107307, 2987141.073836037, 3001217.8475220473, 2916690.342216677, 2904430.3140194393, 2944186.5865323218, 2930830.514776432, 2991984.400691362, 2867464.3173886677, 3028044.8990296754, 3035464.7325247927, 3004401.3026066194, 3021136.852971926, 2897971.43171562, 2851268.0513396375, 2873006.8079832857, 2901941.655177461, 2982056.786633916, 2947604.6522623454, 2944870.0438477285, 2920456.247192138, 2856830.396979043, 3042239.2516859667, 2976502.936749423, 2903638.0295447363, 2938269.1833239226],
        6: [3031595.0481049884, 2951665.9915214335, 2906226.0615872415, 2875271.507811854, 2958362.832857083, 2959882.616278943, 3006929.1681421897, 2861248.944846201, 3016052.223715306, 2980262.007750136, 2866229.8074127226, 2875083.7568909237, 2900952.571322475, 2950053.8051901204, 2904186.1917131557, 2858616.8511774447, 2853875.2994587277, 2946716.220582185, 3003062.3333555018, 3028145.5660931836, 2967842.7668598327, 2909213.5153108505, 2922311.437603953, 2932212.411714499, 2982926.6256876932, 2929572.3115465483, 2911990.7114582774, 2914871.111997747, 2956304.138596672, 2956204.1379762045, 3028142.713252754, 2866500.037667482, 2960165.2609473206, 3014911.741422617, 2960124.0615958697, 2908487.4395332285, 2970646.669371201, 3031669.984980282, 3031940.3944058744, 2922731.559200488, 3030965.935316919, 2855344.1967216674, 2947722.728434609, 2897372.1947187027, 2921368.1008152845, 2966513.014176648, 2984344.308494994, 2869952.5601775236, 2932162.5509347674, 2894432.921628703, 2860052.185965547, 2877256.4449127987, 2943942.5826928313, 3040336.830073515, 2992281.5821868028, 2991504.101310385, 2906600.060797552, 3039496.542810204, 2954930.4079250037, 2990383.698835262, 3003179.686832943, 2909041.0240432, 2898743.244194106, 2907350.4920591465, 2988502.1547996216, 2965157.7727511553, 2903401.149219502, 2859836.337406473, 2980054.9034341685, 3045915.103427284, 2864190.184819238, 2992374.112889252, 2881738.0353261926, 2862850.970082774, 2912212.405729222],
        7: [2857466.9887711154, 3016818.716092492, 2913077.6064228634, 2990314.5109017747, 3005850.5848281793, 2914602.3675658763, 2975797.5720828017, 2881831.050284616, 2860270.410734734, 2946775.073012846, 2943593.377100809, 2930696.8031149535, 2893154.007259675, 3039236.6850755815, 2873805.5611353717, 2856111.0103277587, 2944104.611745601, 2969358.333015162, 2918390.719385591, 3011676.1379485223, 2869276.767412315, 2918816.788531046, 2968839.2980509764, 2880542.340237509, 2984104.643871822, 2905247.1912041623, 2917048.7883538497, 3010206.3168475064, 3042431.4417408835, 2977022.721938969, 3039942.708735117, 3004065.3725126795, 3031067.506248344, 2856764.5420898246, 2938499.661933646, 2958263.4119258295, 3045253.8673877083, 2893608.016140336, 2918962.176096386, 3041305.368846065, 2904573.8589192424, 2974903.926412588, 2969090.4242057777, 2955934.497895561, 2930906.303558589, 2913244.2453656825, 2963745.5339428205, 2867117.055938531, 3030303.7076133653, 2953048.548686549, 2859834.9206171464, 2861462.3286654707, 3023666.503518695, 2975462.754461977, 3042407.287571905, 2865142.6733515253, 3026657.2746469406, 2851063.6160211684, 2966175.1358041433, 3037598.7801922276, 3039113.939960746, 2902876.289027009, 2942154.284676558, 2953392.4880010895, 2988481.9934088183, 3040627.8166559804, 2893946.59850302, 2880959.8010251215, 2970139.595310223, 2983382.3819910735, 2974162.872255304, 2879880.6703197216, 3045607.7057202845, 2984666.294079512, 3020642.790152654, 3039824.9211644283, 2994637.413293488, 2904831.5651835026, 2881378.3931277646, 2969565.296787092, 2861802.851241568, 2883095.2238455685, 3003662.2488966184, 3046016.7496696613, 2959366.9444495067, 2985467.6007516617, 2989847.36671523, 3010769.412833811, 3031364.015282845, 2885133.0859168973, 2937693.0413209214, 2875305.633826346],
        8: [3035595.7153136027, 2944084.5406969977, 2976309.797423503, 2928277.9123513815, 2888509.7051476436, 2917184.831629454, 2876127.8034850266, 3037608.625067506, 3014565.251341091, 3028185.9877317096, 2982004.309593578, 3001631.95071429, 2904366.4100265163, 2944424.1109162983, 2929691.1776087377, 2968949.5794255123, 2934667.4840711607, 2955724.7587477877, 3019060.645302067, 3046643.9134661327, 2940338.7194910813, 3001143.637959417, 2985230.127157892, 2951654.8396535716, 2892005.8266093004, 2994345.022349397, 2855788.9141646586, 2947797.711933554, 2953507.8133383426, 2900731.041493802, 2884680.134985945, 2898622.266771645, 2986392.419900688, 2890947.5003113276, 3042731.864749695, 2985516.9890682516, 2970978.053326139, 3034718.491368557, 2912743.086250065, 3002130.269280572, 2980936.416281626, 2941636.1173463664, 2957617.8133388227, 2857948.8185691047, 2874431.343107106, 3005758.555165555, 2930394.3194384454, 2899052.7447299575, 3045797.893935134, 2970379.5136043094, 2957210.679904184, 2963708.621091374, 3037535.691913375, 2887570.7448555944, 2898243.723563379, 3035827.9393717176, 3027846.7860941053, 2958534.7264157566, 3047922.754606881, 2909782.7968119937, 2936087.264366252, 2956383.5834021643, 3029394.7484353096, 2866867.883879221, 2862643.9811379556, 3024498.2158434778, 2956737.674578138, 2871420.6160299047, 3004096.8054186543, 2912788.536235811, 2898491.60270982, 3027032.9775493843, 3001283.5174248256, 2950629.7792132124, 2889885.165188235, 2881018.82528431, 2943529.556058039, 3036608.130323242, 3020257.4624347836, 2944154.2104198663, 2881799.760165448, 3018886.034636147, 2995482.396442268, 3004194.271466752, 2899901.188663102, 2959224.978409444, 2936551.279451871, 2888966.9252050593, 3000608.3544695848, 3043457.827330502, 2972161.1241983073, 3001224.9226853163, 2903350.8267455967, 2851096.899327314, 2917178.543129062, 2919953.8934470518, 2893352.406880833, 2934889.109692195, 3026205.087490853, 2894119.9187344164],
        9: [2974534.2275652564, 2974624.1657610717, 2977015.7701621833, 3025594.4599880013, 3042580.2355748294, 3034840.5781392143, 3046956.506721908, 3011379.883731121, 2949832.193033215, 2918095.379793548, 2873073.526749169, 2908367.7004419584, 2981371.661950102, 2850912.5113776433, 3048835.9363430305, 2907342.90392888, 2864378.75594885, 2989321.243038565, 2909804.91521816, 2889866.420853324, 2858135.197597315, 2977379.7237915727, 3013381.215744717, 3047246.1946630115, 2885786.495368269, 2963100.4894333226, 2941016.56635654, 2925869.619015001, 2939362.5885909023, 3006277.872415438, 3028122.77243264, 3007622.759608702, 2856497.833426947, 2898500.206646188, 2878651.4608612135, 3007199.3628364, 2995178.0671885354, 2861838.105116228, 2931507.7311925227, 2851987.6064518257, 2974744.3888763115, 3033891.3323610206, 2932324.8876652643, 2991626.5945157907, 2928257.4436309305, 2928383.8086592597, 3000235.716300873, 2997234.125566605, 3008697.9231222044, 2892282.404819915, 2968120.8578587775, 3048032.375811553, 2852252.6450238666, 3000882.9693815387, 2952368.3370495588, 2950614.756334064, 2892289.6567689143, 3034829.3957933886, 2990459.5607396965, 3003176.4180799285, 3025819.217776553, 2933475.072902348, 2855074.89797761, 2909083.0162132387, 2976290.847930714, 2876418.052442836],
        10: [2997838.632693916, 3026223.7570895604, 3009111.1384433107, 2878118.475872795, 2969796.6586557943, 3025107.9158191043, 2918773.8586321245, 2960825.703959777, 3031691.511688288, 3025988.987710683, 3026387.7738227807, 2850801.2766494215, 3049246.5905454094, 2940304.564318099, 3001581.195086329, 2988825.3993132226, 2922446.5157521376, 3027426.039397477, 2938450.7306763977, 2882348.637990276, 2946921.9978166474, 2904858.44833068, 2917071.415302292, 2982227.5224180967, 3045272.5901280753, 2928530.494201378, 2923249.9491463834, 2853335.5913229994, 2955311.8010399737, 3027415.461604491, 2992027.6040173406, 2907997.8857174194, 2984241.842617959, 2978266.5310366866, 2946443.1030956046, 2890797.5771374037, 3044219.551706957, 2967003.000763283, 3022159.932889675, 2927786.308026752, 2906458.540006584, 2981386.692135453, 3011834.074645858, 3016466.1890264177, 2979073.849355092],
        11: [2963759.3770921105, 2978605.2325274623, 2926484.1078089057, 3046300.768308592, 2927015.8303542514, 2853072.007041348, 3021422.8385262955, 2886184.339308583, 3049471.2110784035, 2897058.0399097656, 2908342.2534384225, 2899649.690593569, 2964883.3512613294, 2999146.593885867, 2942681.9246378234, 2884513.564969154, 2984771.6913983854, 2888084.7422588803, 2974126.7163239545, 2850012.270051625, 3005828.066836205, 2982915.7103433763, 2908129.253078752, 3049844.324348834],
        12: [2908749.164291671, 3002286.3805507324, 2944976.572851964, 2960580.648614382, 3008747.182187993, 3036332.4208598663, 2932258.472416089, 2973774.028191827, 2965090.474820772, 3040144.3674973506, 2878962.8928862982, 2852311.441398098, 2931351.7739109052, 3016577.758243948, 2954498.8374736244, 3048355.6616452346, 2877692.812108808, 2966192.5925841467, 2993926.2143589384, 2998795.638022761, 3032267.362350828, 2887432.9685264803, 2975870.069472448],
        13: [3011956.9586416055, 3048915.4495306094, 2996506.6679466, 2946842.7114828913, 3034186.4850536156, 3045097.247842205, 2878820.849966185, 2895731.6614682134],
        14: [2998422.203000462, 2878387.9359526113, 3010054.3009105735, 2942674.2003593813, 3002190.281059317],
        15: [2995773.6448607384],
    },
    [RowsType._16]: {
        0: [3034232.5171109647, 3198365.7135828207],
        1: [3150032.55128943, 3122675.1824912257, 3090167.155244298, 3034740.0007295976, 3104258.5126568573, 3065003.048774543, 3019485.537232806, 3060922.051972748, 3102065.7268135347, 3118638.589959657],
        2: [3090948.6641639536, 3004408.0631046956, 3058844.9465002343, 3011293.3032653304, 3164210.271419799, 3168991.3732410916, 3045371.9823465743, 3091261.8247978543, 3101270.430610351, 3097694.502028673],
        3: [3050497.511524164, 3123221.294087889, 3199654.8995098523, 3050205.9671985116, 3142194.675660019, 3193685.4380716453, 3036266.1698356485, 3132079.0760166976, 3034209.319869475, 3006934.0834015156, 3071786.265684273, 3006133.5987295075, 3094906.48166454, 3034073.6988690984, 3044203.6551850997, 3045376.52951291, 3186081.2760210484, 3185321.9314360917, 3192753.6468294356, 3007281.1944491672, 3005605.678125902, 3095483.9800986517, 3047595.1762728603, 3164628.861499122, 3163922.1089545107, 3082271.6396898166, 3080784.9591296497, 3038842.1281495136, 3114692.5485552293, 3004032.5619025226],
        4: [3141504.0864427686, 3150521.328560509, 3071034.636395701, 3005854.3382030292, 3043248.2258293806, 3099124.046854, 3191538.711726018, 3080113.168861238, 3053213.2861680896, 3179571.210905073, 3194925.5387779903, 3124927.3111097203, 3003516.024394494, 3007003.6484635877, 3106059.504268816, 3082435.416106173, 3054411.5709973155, 3016393.562277679, 3112471.099171847, 3129893.0029735537, 3031036.69907472, 3031045.7415401624, 3092112.391658095, 3177324.5292133954, 3010242.748834626, 3107973.810212534, 3042616.6675266256, 3001830.5398853887, 3053391.6690500462, 3073905.289881152, 3031029.9046491836, 3147333.4896361926, 3043410.9896995593, 3024859.594037099, 3029675.5106401388, 3075420.562963322, 3068601.822379031, 3079578.6022201455, 3119835.2698229025, 3025929.236737888, 3150222.480872156, 3128034.85114254, 3000717.9023661716, 3105425.3352985666, 3009022.3552283184, 3157604.673522715, 3152791.9040918946, 3104714.732500692, 3128827.615927114, 3010675.9921323396, 3044934.347321541, 3002580.1883673677, 3173723.5070320433, 3167737.0570202414, 3147765.3315488338, 3043730.034476704, 3092019.009800812, 3019131.2659085365, 3164744.888344153, 3125542.7726126676, 3132014.098789052, 3150835.8645021464, 3038641.2918736106, 3087218.3390945527, 3192380.0640774937, 3190346.195287753, 3195342.66710317],
        5: [3131647.8955876776, 3003920.1883239816, 3195860.7217146563, 3145295.7555000065, 3197260.1926319906, 3103679.1926865163, 3066941.814190597, 3169708.8514118157, 3097169.823809751, 3134942.145708663, 3162723.2624693317, 3092139.344325127, 3154741.1992299715, 3133393.568996272, 3177700.381428807, 3066775.951795594, 3058706.9027781435, 3070596.840991218, 3169137.3501878614, 3014246.467049185, 3169899.376970126, 3164143.8438467993, 3156537.774001903, 3095341.365705168, 3196536.5650508106, 3186794.1040909053, 3108636.8231897177, 3139797.916888425, 3080829.8857912445, 3193286.305120793, 3046037.404155348, 3104542.5819226354, 3186587.9204358933, 3129397.2227589297, 3144150.270130911, 3000273.7005409743, 3061760.7838139855, 3171984.5187880583, 3157768.2433093623, 3101363.0931890937, 3049390.6437997706, 3171122.8863765886, 3092830.557457792, 3090519.2706666845, 3043096.736144393, 3163797.737803938, 3166455.2373703825, 3193796.1666759048, 3119443.1992225065, 3102186.687196315, 3053752.591002895, 3071255.7257851423, 3002883.053238018, 3126680.6716152877, 3138035.3455035295, 3138179.906669905, 3061114.7801194126, 3004615.9457405326, 3141598.863735914, 3055259.8504413976, 3141012.1371317664, 3194308.1670193328, 3063359.32311964, 3035712.6998677324, 3184787.187326348, 3079585.752921358, 3197923.270800494, 3194256.786643697, 3016420.2526585977, 3171065.9700741256, 3160771.2888913564, 3038838.8695635125, 3094795.6615783717, 3142326.4634414543, 3134746.554560742, 3009481.30252294, 3039677.1939249504, 3162623.2320437934, 3002595.8298181393, 3120630.0886294264, 3057289.401194589, 3033883.5667906147, 3134951.5171614573, 3037771.3828088674, 3192026.8029223233, 3163208.898232861, 3037698.130626903, 3034893.187297391, 3081212.903984887, 3115137.8735645027, 3127888.7919480377, 3120908.783989926, 3182405.6805978357, 3100982.6487877, 3047522.5217853277, 3125841.491328346, 3137166.919889634, 3103732.6481884425, 3135132.527936352, 3126055.5078247464],
        6: [3030148.375321821, 3014672.48318303, 3140191.291444196, 3167751.3691067933, 3095172.7227677004, 3182467.1369031477, 3147062.982070398, 3159115.001225004, 3163222.669519139, 3018466.2157467734, 3182554.9528846974, 3069516.1914605917, 3167683.4014565856, 3042831.1022991464, 3160243.820888551, 3192531.444552278, 3136834.813019434, 3075935.951342652, 3063909.0488471305, 3157020.11616565, 3196028.9550569872, 3055628.8734012856, 3017740.6149146752, 3186943.3625438605, 3137895.7750138813, 3107969.299408071, 3048049.550494406, 3171796.773259495, 3085052.0088748755, 3157428.9096646863, 3095794.576730674, 3097491.096132027, 3082779.711796714, 3068022.8168677078, 3005943.2391898204, 3001704.5082161967, 3103849.9118679976, 3044050.0575535917, 3112758.7679304318, 3180630.7079482963, 3097679.877974581, 3010572.1337985336, 3098617.289943831, 3087286.1399215953, 3072581.853864587, 3135233.2657988244, 3085801.3346903226, 3170814.0169590926, 3077450.7792879883, 3136238.2998976465, 3061669.4593456644, 3005832.355222748, 3073014.4748843587, 3149280.8049082966, 3195087.6383403027, 3124817.881690601, 3108837.5002336325, 3137044.6673061284, 3001480.6258432115, 3008015.0545677277, 3186798.8212277913, 3193148.327979109, 3184310.708265837, 3026172.711685987, 3112366.506741649, 3064216.8777407953, 3011377.599825727, 3123065.165974845, 3134002.2607975793, 3086405.5471042637, 3150929.7386790374, 3103790.117135167, 3007818.8941925396, 3106551.396000039, 3079194.050905878, 3054872.4430281823, 3025885.8305513137, 3019208.3597380635, 3109346.8172981194, 3044521.906858498, 3167077.34722097, 3036511.657628846, 3102754.8821651516, 3115210.7530298973, 3191770.358837782, 3028885.3158692927, 3163797.8596430086, 3059464.927135005, 3199469.7055091676, 3197375.2248439007, 3051788.947689831, 3117398.6830140804, 3079722.4603169253, 3012724.651288923, 3066075.3436364816, 3101231.2967178496, 3083639.5850938885, 3038321.934060169, 3181265.995317773, 3005367.1163302124],
        7: [3043836.1869665086, 3095239.140464147, 3108339.901138139, 3146227.6213962594, 3175219.7156241504, 3137978.7585630417, 3054917.519166567, 3040794.405248485, 3073972.7791026076, 3130762.6585429194, 3049271.3736253837, 3098529.2176935063, 3004083.3064468647, 3059023.683310133, 3115987.965542596, 3192107.461469797, 3153102.5938459886, 3155847.4235627907, 3006621.2069945415, 3072519.8475936786, 3122104.801081778, 3011276.90934153, 3173891.113415196, 3003279.7969073984, 3163574.010472595, 3067495.2413327354, 3070130.06884201, 3187103.9708734793, 3082293.356188941, 3034841.2429308915, 3152281.8860009513, 3124967.4783658134, 3046440.6221166914, 3065020.463663127, 3194677.8759810496, 3118365.931207736, 3177287.2915173885, 3134495.360143558, 3191435.960111338, 3073117.6156437993, 3005105.7094984427, 3111052.527021743, 3115082.2270391244, 3181477.7739381166, 3069140.2048057495, 3080536.7557571605, 3016709.7517632484, 3068215.866347498, 3162546.8448069375, 3005951.2404225306, 3197393.705642768, 3078489.2503261017, 3061062.3686639788, 3009113.792090512, 3117161.058099968, 3096720.577168791, 3038191.771349188, 3025235.752939425, 3126394.781644004, 3134075.5415123655, 3105652.1643355666, 3158239.6457309634, 3093281.42980255, 3112361.024586623, 3169819.329112062, 3066730.6575974505, 3007179.483400637, 3030649.9859794853, 3080071.1722781057, 3040283.3029740774, 3155754.5634749504, 3098352.522042289, 3113042.2378410012, 3186576.32567935, 3016841.3056551735, 3154926.359977354, 3166208.210390943, 3122865.8351572184, 3094228.5032415246, 3140992.791723015, 3049326.5424887664, 3030557.180842057, 3108321.0312477704, 3096221.0875415895, 3122600.6970258374, 3190367.134625404, 3121703.258424847, 3113709.0649272846, 3094169.738936684, 3130318.778381601, 3069780.364078196, 3083633.871282478, 3112821.7737982487, 3120791.484431342, 3140206.0291744247, 3048247.4467982934, 3145428.0670924727, 3097389.8308013277, 3075468.8364490867, 3055453.22250975],
        8: [3124711.139677445, 3147940.9379446916, 3104669.5495840767, 3072667.4190783584, 3048635.395500318, 3033292.563157756, 3070680.9185966887, 3006119.8523004125, 3157105.93028007, 3072338.943633141, 3125684.041771365, 3093708.738548659, 3145983.4102760972, 3169617.4169894336, 3142812.1605886067, 3188611.3766417727, 3075408.325084172, 3156796.3372875145, 3057130.581130847, 3182900.85656136, 3099830.667256734, 3128425.0964477025, 3107703.048098889, 3159217.2701317044, 3141690.7032258995, 3101800.9559552753, 3197851.6065002996, 3179024.174376662, 3021555.789057449, 3116131.827185753, 3078465.5559450365, 3055886.8253568998, 3187453.404031712, 3082090.3828837858, 3137560.6731119324, 3154428.446829684, 3147705.4715573774, 3046950.6818767637, 3174156.061470582, 3150966.7747788792, 3092798.9595563984, 3184563.0663978015, 3199573.991414102, 3059672.2407605806, 3092631.827576041, 3053612.024126064, 3146075.3790358626, 3067556.4084823243, 3152633.940671415, 3006636.359314495, 3059924.2981253816, 3169113.213290057, 3025276.0675465055, 3163150.1155560156, 3172074.8863985124, 3185951.21492523, 3091740.6713829045, 3060318.7584088887, 3064268.357899136, 3067360.7368577262, 3023216.2258014055, 3188066.543721813, 3148446.9755159784, 3035341.3882535715, 3065216.170620526, 3116159.361631713, 3082133.7300650873, 3005634.9715943397, 3139242.407543023, 3020962.427120836, 3007550.5188921927, 3002211.660406768, 3122622.1586785153, 3076939.2124762, 3112991.328979453, 3070909.8992655207, 3038854.1609449163, 3009765.47915588, 3140986.339244315, 3190966.2510638344, 3184538.247107249, 3040086.8280584067, 3173712.316771867, 3057422.7068026527, 3092381.1575159393, 3068124.5374447154, 3020740.1853152704, 3100413.4026453425, 3158148.46672986, 3018032.8269084464, 3087119.4752488653, 3106173.246213138, 3177369.6679162537, 3015992.171947452, 3141445.143881558, 3065064.3255459755, 3108615.9062981573, 3028263.2518458767, 3007486.1872643232, 3053809.632902169],
        9: [3053466.4504403225, 3009803.4026991446, 3079383.901496957, 3105167.438793385, 3107194.3536526184, 3033255.9967789734, 3193714.8019822654, 3174485.8568888376, 3098488.405928597, 3007443.676916257, 3191256.9266303624, 3193171.5042757643, 3193272.203898231, 3015173.2662337204, 3114368.9786488255, 3191754.916533028, 3066391.1169891693, 3007919.412816432, 3085613.418928805, 3114624.6938818498, 3125668.286893919, 3112198.602790801, 3006200.284314065, 3140292.6014043884, 3144303.4117537956, 3058468.961618518, 3129081.8339312626, 3029108.5801458983, 3029222.455130211, 3169988.4711300517, 3018343.724626257, 3075023.5687309406, 3195778.9556471677, 3025268.4025984663, 3183316.131144876, 3087039.1476201476, 3025473.731461014, 3098918.9115504944, 3076377.5822346113, 3187722.8516728594, 3121648.463797609, 3147602.638663441, 3131699.283188281, 3113683.61226863, 3129977.3459882173, 3199887.856258386, 3157549.3337047976, 3152482.7966591464, 3150187.110807799, 3163942.361162462, 3006699.9239129066, 3006199.1362425345, 3012304.5636150483, 3139255.152732967, 3087667.3895137873, 3024792.5682263304, 3106536.714452912, 3157448.4421640853, 3099743.5265476014, 3014682.456652301, 3118245.5528093386, 3032127.180746224, 3077212.194850295, 3122028.005165151, 3036396.073834748, 3161905.024073682, 3163812.6793454047, 3116685.6037467094, 3036236.4449111153, 3063359.1662826226, 3116939.5203221836, 3085625.7665488636, 3092435.2567504793, 3164537.3713057423, 3051493.686575374, 3086073.5954747633, 3020495.9118081257, 3058889.9145017634, 3175049.4936503186, 3142421.505718545, 3005719.404125131, 3168979.9772123154, 3144909.670308047, 3033319.606958396, 3134999.1194135924, 3050276.1956744757, 3031838.2804344334, 3193941.650073901, 3184018.870754765, 3078681.391984156, 3128517.2736767256, 3064198.8772125654, 3048647.4666483845, 3183633.6423212755, 3032042.709488852, 3139839.692501227, 3125521.859606329, 3167819.6984723364, 3107963.9394817525, 3177175.1223617285],
        10: [3148406.231370187, 3081112.27429398, 3160149.9984406447, 3157183.5733780656, 3112278.861383319, 3122038.9395654514, 3021054.588444846, 3011382.7539955615, 3026602.709606682, 3145155.852982834, 3008814.8384030308, 3043367.1916480297, 3036076.936341618, 3114426.575561837, 3077935.9854173036, 3144556.2824391495, 3102763.976559728, 3114814.1094848504, 3111592.504265752, 3109138.839862644, 3137454.8341997685, 3153858.736960109, 3009372.78221702, 3124477.234609918, 3162467.305490131, 3092639.075634207, 3088842.0869764555, 3034978.9336094093, 3186161.8079561032, 3068143.120776013, 3096748.3532081996, 3054288.439768267, 3181726.5985819926, 3111822.701646693, 3133541.6169119747, 3146718.6202894635, 3141174.18768329, 3093696.8797568223, 3015420.142120786, 3183980.9190558223, 3004883.0649866434, 3021551.4520884403, 3188388.612041815, 3007362.1308485535, 3017205.7355626277, 3027518.704478461, 3015245.142837796, 3163308.906900441, 3088858.752710175, 3185348.4966665143, 3010857.011706711, 3032428.2912417, 3124965.3940669983, 3088058.2575322385, 3021257.2936845133, 3012667.8772596545, 3002946.773723375, 3039488.7969616586, 3116305.700273877, 3036900.0315677715, 3027591.183147671, 3185588.1814208743, 3137883.8993641324, 3072417.911725136, 3055368.462927882, 3129661.801047714, 3160504.4638722804, 3024815.3288421175, 3063590.54609517, 3127623.418050505, 3020706.1472720196, 3195052.1953002284, 3112304.7677555466, 3070378.774554642, 3012831.35190355, 3019946.780888941, 3190818.1316129947, 3046574.3257878423, 3177906.304534944, 3008337.835611116, 3135655.019310002, 3070389.480851012, 3106056.1070268922, 3079909.942132117, 3186147.2295245035, 3013269.7035478437, 3111636.746563494, 3177611.946986063, 3138285.0883963406, 3155916.0178424115, 3048756.142035264, 3018747.6550581767, 3011924.2078725263, 3188428.6250127344, 3093849.2008072757, 3194675.283916666, 3160945.390826182, 3176648.9245912987, 3110431.9540599966, 3107150.9968606797],
        11: [3195940.1612171717, 3160869.7095823027, 3002684.394573914, 3145337.4685626575, 3159833.7812566576, 3197357.1303951214, 3048941.678649066, 3017110.1296736244, 3093581.4352812506, 3146240.7779300995, 3072099.600948978, 3154383.87277081, 3013549.1684634183, 3018180.5246651573, 3012655.5866455464, 3012860.6865130644, 3197031.953006945, 3000935.4119267217, 3019041.3499123286, 3132653.607201715, 3023835.159143845, 3129764.727477851, 3027449.6348607056, 3036337.5537623838, 3143832.141822296, 3057627.6938522225, 3140434.190185832, 3069114.8265996026, 3165939.246520588, 3132759.498660751, 3068935.123356977, 3195049.181977661, 3088870.6569611942, 3033597.576343699, 3129071.977207667, 3115296.270378739, 3018823.884203765, 3137850.7916069166, 3144002.614014456, 3023566.6884950968, 3152330.8022532533, 3191602.441150699, 3023102.8356487434, 3063520.966583558, 3103255.048303288, 3087871.1993540777, 3099515.3416338684, 3150098.1611564714, 3017578.569573483, 3074704.6632185034, 3031303.215136966, 3124838.6660828963, 3084323.1086944523, 3146666.839346199, 3080202.2198752896, 3199067.0390589377, 3181140.7741621365, 3159450.577418785, 3087866.439619288, 3165795.0311683924, 3002675.243975744, 3046432.7328871787, 3094084.1104726996, 3001939.9620461534, 3024755.264378515, 3002793.3301616763, 3050319.6939322934, 3144073.5504310443, 3027828.0726924394, 3101978.8760334062, 3142718.386026561, 3062712.447351687, 3132514.053534928, 3178179.214295114, 3078812.273592529, 3027353.386362437, 3062877.77450287, 3063844.4423142364, 3070908.8032176914, 3085459.5650136406, 3132025.8789299293, 3037922.2839147197, 3102440.9001580663, 3175236.982642004, 3087902.952234587, 3180492.505580296, 3074221.679981978, 3072710.149563522, 3062057.1314203152, 3055566.999465734, 3016382.552256065, 3099651.5229392303, 3057958.5467636865, 3044150.908305325, 3191537.0559424134, 3056343.9835836957, 3032777.1542665926, 3130301.3840049463, 3161616.9902442447, 3191484.10264865],
        12: [3089433.146164661, 3022209.02027475, 3147231.3909106567, 3016325.846602064, 3142349.584222485, 3087008.7974869334, 3157857.775908506, 3116121.212344339, 3130095.4020920857, 3024088.5033841794, 3021840.982779317, 3139278.173416526, 3018107.5159043837, 3023759.7373634544, 3032084.9966766564, 3075003.769926329, 3173868.1203770274, 3160791.504275292, 3070661.696792349, 3053998.1666884953, 3174883.897527144, 3162149.8699182137, 3084864.6205340466, 3127381.5154339517, 3018831.4565822803, 3019702.1790341125, 3026865.887313731, 3013895.3985429616, 3052726.035802401, 3188130.019361395, 3138336.7468739925, 3075567.696194131, 3148612.198312903, 3177442.556334424, 3077012.394799153, 3170634.269849824, 3155786.3335000244, 3049747.5127958953, 3176310.4363448275, 3029929.465191649, 3116573.7108686743, 3161628.7530902894, 3154084.0337518714, 3035999.1235325914, 3014095.105055283, 3176933.4173967815, 3062099.4265846172, 3181475.94923034, 3107003.2376828794, 3087010.207984609, 3093568.4794627074, 3071204.0580184497, 3177452.537955555, 3142459.1429767488, 3132961.982276182, 3182018.8627964184, 3167106.4105153596, 3016515.29365613, 3199396.515380202, 3151506.589517065, 3028109.577907437, 3023787.609950645, 3040247.6313377186, 3087004.90593931, 3035093.281738851, 3058243.4766117334, 3177912.9928838373, 3185418.443449555, 3142544.7972123353, 3054879.350908547, 3028063.809244617, 3035111.033684948, 3022173.638936304, 3155485.1634757114, 3055122.48144627, 3023954.467217818, 3025475.7198619875, 3148910.4350935607, 3014963.114667003, 3155613.1497094487, 3179522.6401680536, 3178990.0907653356, 3114739.0861960948, 3112147.0015108422, 3149734.826158, 3189630.930108495, 3013093.309375507, 3116352.3029318033, 3123865.54685686, 3061941.20502046, 3117847.695649255, 3012998.8505165717, 3187775.6092794603, 3195097.702460313, 3068753.4095837474],
        13: [3183495.649929052, 3008939.1377897705, 3132462.6486092648, 3016278.432750917, 3016110.5133429477, 3068140.843951653, 3095219.39848082, 3070837.7100039455, 3026343.29078366, 3096740.2112010703, 3069661.2526975195, 3146929.85389298, 3194912.238717851, 3148226.1428767787, 3059734.280833805, 3153341.09675182, 3078385.3060901104, 3059636.1582537163, 3110826.0783431153, 3008341.4493920184, 3123596.8742779107, 3036093.7459885566, 3035121.479477105, 3029848.7019855753, 3188036.24632413, 3018206.6591329966, 3052025.691734414, 3022610.802300678, 3147498.2372589307, 3174794.3700086153, 3150357.396745845, 3063342.5548832207, 3029923.837028537, 3131836.939772407, 3092522.4187838174, 3173824.2952739643, 3118589.6134845377, 3074492.329603247, 3093595.047300446, 3185376.7973933066, 3130791.413291166, 3139563.057126166, 3184164.910527827, 3014622.7624949375, 3012426.7870499366, 3011064.089830101, 3125457.802263367, 3145810.9720886373],
        14: [3197721.414539112, 3158589.4278249135, 3061535.5652070786, 3112588.3006620617, 3140676.850364722, 3162433.9989759843, 3161556.552505785, 3016606.0951335635, 3011859.572378311, 3027145.106660055, 3171141.50798778, 3016224.839284282, 3152033.988576473, 3146022.78475661, 3174279.1319699017, 3012704.548035626, 3089249.2009119433],
        15: [3150807.7813067543, 3183682.998048172, 3083685.9036585945, 3166843.997201811, 3087164.516415208, 3150800.29391114, 3160074.7201619786],
        16: [3075710.894547483, 3029127.663759934, 3073923.2178260977, 3028551.356458825],
    },
}