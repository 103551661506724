import { obstacleRadius, sinkWidth } from "./constants";
import { pad } from "./padding";

export interface Obstacle {
    x: number;
    y: number;
    radius: number;
}

export interface Sink {
    x: number;
    y: number;
    width: number;
    height: number;
    multiplier?: number;
}

export const createObstacles = (numRows: number, width: number): Obstacle[] => {
    const obstacles: Obstacle[] = [];

    for (let row = 2; row < numRows + 2; row++) {
        const numObstacles = row + 1;
        const y = (row - 1) * 35;
        const spacing = 36;
        for (let col = 0; col < numObstacles; col++) {
            const x = width / 2 - spacing * (row / 2 - col);
            obstacles.push({x: pad(x), y: pad(y), radius: obstacleRadius });
        }   
    }
    return obstacles;
}

export const createSinks = (numRows: number, multipliers: number[], canvasWidth: number): Sink[] => {
    const sinks = [];
    const SPACING = obstacleRadius * 3 + 2;
    const NUM_SINKS = numRows + 1;

    for (let i = 0; i < NUM_SINKS; i++) {
      let x = 0
        if (NUM_SINKS % 2 !== 0) {
            x = canvasWidth / 2 + sinkWidth * (i - Math.floor(NUM_SINKS / 2)) - SPACING;
        } else {
            x = canvasWidth / 2 + sinkWidth * (i + 0.5 - Math.floor(NUM_SINKS / 2)) - SPACING;
        }
      const y = (numRows + 1) * 36 - 20;
      const width = sinkWidth;
      const height = 30;
      sinks.push({ x, y, width, height, multiplier: multipliers[i] });
    }

    return sinks;
}
