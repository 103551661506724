import { FC } from 'react'
import classNames from 'classnames'
import s from './History.module.css'
import {svgIcons} from "../../../../assets/svgIcons";

const History: FC<IProps> = ({ results, className }) => {
    return (
        <div className={classNames(s.history, className)}>
            <span>{svgIcons.arrows.left}</span>
            <div className={s.results}>
                {results
                    .slice(0, 5)
                    .map((odd, i) => (
                        <button
                            key={i}
                            className={classNames(
                                s.results__item,
                                odd < 1 && s.results__item_gray,
                                odd > 1 && s.results__item_green,
                            )}
                        >
                            {odd.toFixed(2)}x
                        </button>
                    ))
                }
            </div>
        </div>
    )
}

export default History

interface IProps {
    results: number[]
    className?: string
}
