import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import s from './Game.module.css'
import PlayingField from './PlayingField/PlayingField'
import Control from './Control/Control'
import { useSearchParams } from 'react-router-dom'
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'
import { ChangeResult, InitialState, SpinResult } from '../../types/Messages'
import InitialStateContext from '../../context/InitialStateContext'
import BalanceContext from '../../context/BalanceContext'
import GameConfigContext from '../../context/GameConfigContext'
import Api from '../../api'
import Popup from '../Popup/Popup'
import { useSounds } from '../../utils/useSounds'
import PopupsOpenContext, { IPopups } from '../../context/PopupsContext'
import ProvablyFairnessModal from './ProvablyFairnessPopup/ProvablyFairnessPopup'
import RulesPopup from './RulesPopup/RulesPopup'
import ErrorContext from '../../context/ErrorContext'
import yo from '../../assets/yo.png'
import OldHashAndKeyContext from '../../context/OldHashAndKeyContext'
import { BallCount, RiskType, RowsType } from '../../types/Game'
import { BallManager } from '../../game/classes/BallManager'
import {outcomes} from "../../game/outcomes";
import {randomArrValue} from "../../utils/randomArrValue";
import {pad} from "../../game/padding";
import {ballColor} from "../../game/colors";
import {useTranslation} from "../../hooks/useTranslation";
import {checkCurrencyCode} from "../../utils/checkCurrencyCode";

const Game = () => {
    const { t } = useTranslation()
    const [, setErr] = useContext(ErrorContext)

    const { betSound, autoplaySound, clickSound, winSound, crashSound} = useSounds()

    const [params] = useSearchParams()
    const partnerId = params.get('PartnerId')
    const gameName = params.get('GameName')
    const oneTimeToken = params.get('OneTimeToken')
    const mode = params.get('Mode')

    const [connection, setConnection] = useState<HubConnection | null>(null)

    const [balance, setBalance] = useContext(BalanceContext)
    const [initialState, setInitialState] = useContext(InitialStateContext)

    const [{ isMute, windowBallsCount }, changeGameConfig] = useContext(GameConfigContext)
    const [popupOpen, setPopupOpen] = useContext(PopupsOpenContext)

    const openPopup = useCallback(
        (popup: keyof IPopups, value: boolean) => {
            setPopupOpen(prev => ({
                ...prev,
                [popup]: value,
            }))
        },
        [popupOpen]
    )

    // Control
    const [amount, setAmount] = useState<string>('0')
    const [rows, setRows] = useState(
        localStorage.getItem(process.env.REACT_APP_ROWS_KEY as string) as unknown as RowsType | null ?? RowsType._8
    )
    const [balls, setBalls] = useState(
        localStorage.getItem(process.env.REACT_APP_BALLS_KEY as string) as unknown as BallCount | null ?? BallCount._1
    )
    const [risk, setRisk] = useState(
        localStorage.getItem(process.env.REACT_APP_RISK_KEY as string) as RiskType | null ?? RiskType.Low
    )

    const rowsCount = useMemo(() => {
       // @ts-ignore
       const rowKey = Object.keys(RowsType).find(key => RowsType[key] == rows)!
       return +rowKey?.replace('_', '')
    }, [rows])

    const [oddsInfo, setOddsInfo] = useState<number[]>([])

    const [isFetching, setIsFetching] = useState(false)

    const [ballManager, setBallManager] = useState<BallManager>()
    const canvasRef = useRef<HTMLCanvasElement>(null)

    const [history, setHistory] = useState<number[]>([])
    const [oldHashAndKey, setOldHashAndKey] = useContext(OldHashAndKeyContext)

    const [numberOfRounds, setNumberOfRounds] = useState('10')

    const [isAutobet, setIsAutobet] = useState(false)
    const [isAutobetRunning, setIsAutobetRunning] = useState(false)

    const [checkBallFinish, setCheckBallFinish] = useState<object | null>(null)

    useEffect(() => {
        if (checkBallFinish) {
            if (isAutobetRunning && !windowBallsCount) {
                runAutoBet()
            }
        }
    }, [checkBallFinish]);

    const onBallFinish = (index: number, startX?: number, numberBalls?: number) => {
        oddsInfo[index] >= 1 ? winSound() : crashSound()

        changeGameConfig('windowBallsCount', numberBalls!)
        setBalance(prev => prev + (+amount * oddsInfo[index]))
        setHistory(prev => [oddsInfo[index], ...prev])
        setCheckBallFinish({})
    }

    const onObstaclesCollision = () => {
        clickSound()
    }

    useEffect(() => {
        if (canvasRef.current) {
            const ballManager = new BallManager(
                canvasRef.current,
                rowsCount,
                oddsInfo,
                risk,
                onBallFinish,
                onObstaclesCollision.bind(isMute),
            )
            setBallManager(ballManager)
        }
    }, [canvasRef.current, amount, rowsCount, balls, risk, oddsInfo, isMute])

    // let [outputs, setOutputs] = useState<{ [key: number]: number[] }>({});
    //
    // async function simulate(ballManager: BallManager) {
    //     let i = 0;
    //     while (1) {
    //         i++;
    //         ballManager.addBall(pad( 620 / 2 + 20 * (Math.random() - 0.5)));
    //         await new Promise((resolve) => setTimeout(resolve, 500));
    //     }
    // }
    // useEffect(() => {
    //     if (canvasRef.current) {
    //         const ballManager = new BallManager(
    //             canvasRef.current as unknown as HTMLCanvasElement,
    //             rowsCount,
    //             oddsInfo,
    //             risk,
    //             (index: number, startX?: number) => {
    //                 setOutputs((outputs: any) => {
    //                     return {
    //                         ...outputs,
    //                         [index]: [...(outputs[index] as number[]), startX],
    //                     };
    //                 });
    //             }
    //         );
    //         simulate(ballManager);
    //
    //         return () => {
    //             ballManager.stop();
    //         };
    //     }
    // }, [canvasRef.current, rowsCount, oddsInfo, risk]);
    //
    // useEffect(() => {
    //     let res = {}
    //     for (let i = 0; i < oddsInfo.length; i++) {
    //         res = { ...res, [i]: []}
    //     }
    //     setOutputs(res)
    // }, [oddsInfo])
    //
    // console.log(outputs






    useEffect(() => {
        if (isAutobet) {
            autoplaySound()
        } else {
            setIsAutobetRunning(false)
        }
    }, [isAutobet])

    const handleInitialState = (data: InitialState) => {
        console.log('InitialState', data)

        setBalance(data.balance)
        setInitialState(data)
        changeResult(rows, balls, risk, data)
    }

    const handleChangeResult = (data: ChangeResult) => {
        console.log('ChangeResult', data)
    }

    const handleSpinResult = (data: SpinResult) => {
        console.log('SpinResult', data)
        setIsFetching(false)

        // if (data.responseCode === 10) {
        //     changeGameConfig('windowBallsCount', windowBallsCount === 1 ? 0 : windowBallsCount)
        //     setErr(
        //         t(
        //             'PLINKOMAXBETERROR',
        //             { maxBet: `${initialState.limits.maxBet} ${checkCurrencyCode(initialState.currencyCode)}` },
        //         ),
        //     )
        //     setBalance(prev => prev + +amount * balls)
        //     return
        // }

        if (data.responseCode !== 0) {
            changeGameConfig('windowBallsCount', windowBallsCount === 1 ? 0 : windowBallsCount)
            setErr(t('UNEXPECTEDERR'))
            setBalance(prev => prev + +amount * balls)
            return
        }

        setOldHashAndKey(data.oldResultHashAndKey)
        console.log('odd', data.result.balls[0].odd)

        if (ballManager) {
            for (const { index } of data.result.balls) {
                const startPoint = randomArrValue<number>(outcomes[rows][index])
                ballManager.addBall(startPoint, ballColor[data.result.riskType])
            }
        }
    }

    const stopAutobet = () => {
        setIsAutobet(false)
        setIsAutobetRunning(false)
        // setIsGame(false)
    }

    const runAutoBet = async () => {
        if (!isAutobet || +numberOfRounds < 1) {
            stopAutobet()
            return
        }

        if (+amount > balance) {
            setErr(t('NOBALANCE'))
            stopAutobet()
            return
        }

        setIsAutobetRunning(true)

        setNumberOfRounds(prev => (+prev - 1).toString())

        await spin(+amount, rows, balls, risk)
    }

    const changeResult = useCallback(
        async (
            plinkoRowType: RowsType,
            ballCount: BallCount,
            riskType: RiskType,
            { oddsInfo }: InitialState,
        ) => {
            if (!connection) return

            const type = {
                plinkoRowType,
                ballCount,
                riskType,
            }
            await connection.invoke('ChangeResult', { type })

            // @ts-ignore
            const rowsKey = Object.keys(RowsType).find(key => RowsType[key] == plinkoRowType)!
            const riskKey = riskType.toLowerCase()
            const odds = Object.values(oddsInfo?.[rowsKey][riskKey] || {})
            setOddsInfo(odds)
        },
        [connection],
    )

    const spin = async (
        amount: number,
        plinkoRowType: RowsType,
        ballCount: BallCount,
        riskType: RiskType,
    ) => {
        if (!connection) return

        if (amount * ballCount > balance) {
            setErr(t('NOBALANCE'))
            return
        }

        if (amount * ballCount > initialState.limits.maxBet) {
            setErr(
                t(
                    'PLINKOMAXBETERROR',
                    { maxBet: `${initialState.limits.maxBet} ${checkCurrencyCode(initialState.currencyCode)}` },
                ),
            )
            return
        }

        if (amount > initialState.limits.maxBet || amount < initialState.limits.minBet) {
            return
        }

        setIsFetching(true)
        changeGameConfig('windowBallsCount', 1)

        // setIsGame(true)

        betSound()

        setBalance(prev => prev - amount * ballCount)

        const spinData = {
            plinkoRowType,
            riskType,
            ballCount,
        }

        await connection.invoke('Spin', { amount, spinData })
    }

    useEffect(() => {
        const connect = async () => {
            let token: string | null = null

            if (oneTimeToken) {
                const { accessToken } = await Api().auth.login(oneTimeToken)
                token = accessToken
            }

            let wsUrl = process.env.REACT_APP_WS as string

            if (token) {
                wsUrl = wsUrl + '?access_token=' + token
            }

            const c = new HubConnectionBuilder()
                .withUrl(wsUrl)
                .withAutomaticReconnect()
                .build()
            setConnection(c)
        }
        connect()
    }, [])

    useEffect(() => {
        if (!connection) return

        const runConnection = async () => {
            connection.on('InitialStateResult', handleInitialState)
            connection.on('ChangeResult', handleChangeResult)

            await connection.stop()
            await connection.start()

            let initStateDto: Object = { partnerId, gameName }

            if (mode === 'demo') {
                initStateDto = { ...initStateDto, isDemo: true }
            }

            await connection.invoke("InitialState", initStateDto )
        }
        runConnection()
    }, [connection])

    useEffect(() => {
        if (!connection) return

        connection.off('SpinResult')
        connection.on('SpinResult', handleSpinResult)
    }, [connection, isMute, isAutobet, ballManager, amount, balls, windowBallsCount])

    if (!Object.keys(initialState).length) {
        return (
            <div className="h-screen flex items-center justify-center">
                <img src={yo} alt="Yo" className="w-[200px]" />
            </div>
        )
    }

    return (
        <div className={s.game}>
            <PlayingField
                history={history}
                canvasRef={canvasRef}
            />
            {/*{JSON.stringify(outputs, null, 2)}*/}
            <Control
                isFetching={isFetching}
                windowBallsCount={windowBallsCount}
                amount={amount}
                setAmount={setAmount}
                rows={rows}
                setRows={setRows}
                balls={balls}
                setBalls={setBalls}
                risk={risk}
                setRisk={setRisk}
                changeResult={changeResult}
                isAutobet={isAutobet}
                setIsAutobet={setIsAutobet}
                numberOfRounds={numberOfRounds}
                setNumberOfRounds={setNumberOfRounds}
                spin={spin}
                runAutobet={runAutoBet}
                isAutobetRunning={isAutobetRunning}
            />

            {oldHashAndKey && (
                <Popup
                    active={popupOpen.provablyFairness}
                    setActive={value => openPopup('provablyFairness', value)}
                >
                    <ProvablyFairnessModal lastRound={oldHashAndKey} />
                </Popup>
            )}

            <Popup
                active={popupOpen.rules}
                setActive={value => openPopup('rules', value)}
            >
                <RulesPopup />
            </Popup>
        </div>
    )
}

export default Game
