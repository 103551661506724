export enum RowsType {
    _8 = 0,
    _9 = 1,
    _10 = 2,
    _11 = 3,
    _12 = 4,
    _13 = 5,
    _14 = 6,
    _15 = 7,
    _16 = 8,
}

export enum BallCount {
    _1 = 1,
    _5 = 5,
    _10 = 10,
    _25 = 25,
    _50 = 50
}

export enum RiskType {
    Low = 'Low',
    Medium = 'Medium',
    High = 'High',
}