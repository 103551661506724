import {FC, MutableRefObject} from 'react'
import s from './PlayingField.module.scss'
import History from './History/History'

const PlayingField: FC<IProps> = ({ history, canvasRef }) => {

    return (
        <div className={s.wrapper}>
            {!!history.length && <History results={history} />}
            <canvas ref={canvasRef} />
        </div>
    )
}

export default PlayingField

interface IProps {
    history: number[]
    canvasRef: MutableRefObject<HTMLCanvasElement | null>
}