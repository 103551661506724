import { ballRadius, obstacleRadius } from "../constants";
import { Obstacle, Sink, createObstacles, createSinks } from "../objects";
import { pad, unpad } from "../padding";
import { Ball } from "./Ball";
import {sinkColor} from "../colors";
import {RiskType} from "../../types/Game";
import {drawRoundedRect} from "../../utils/drawRoundedRect";

export class BallManager {
    private balls: Ball[];
    private canvasRef: HTMLCanvasElement;
    private width: number;
    private height: number;
    private ctx: CanvasRenderingContext2D;
    private obstacles: Obstacle[]
    private sinks: Sink[]
    private risk: RiskType
    private requestId?: number;
    private onFinish?: (index: number,startX?: number, numberBalls?: number) => void;
    private onObstaclesCollision?: () => void;

    constructor(
        canvasRef: HTMLCanvasElement,
        rowsCount: number,
        multipliers: number[],
        risk: RiskType,
        onFinish?: (index: number,startX?: number, numberBalls?: number) => void,
        onObstaclesCollision?: () => void
    ) {
        this.balls = []
        this.canvasRef = canvasRef
        this.ctx = this.canvasRef.getContext("2d")!
        this.width = (rowsCount  + 2) * 36 - 20
        this.height = rowsCount * 36 + 50
        this.ctx.canvas.width = this.width
        this.ctx.canvas.height = this.height
        this.obstacles = createObstacles(rowsCount, this.width)
        this.sinks = createSinks(rowsCount, multipliers, this.width)
        this.risk = risk
        this.update()
        this.onFinish = onFinish
        this.onObstaclesCollision = onObstaclesCollision
    }

    addBall(startX?: number, color = 'red') {
        const newBall = new Ball(
            startX || pad(this.width / 2 + 13),
            5,
            ballRadius,
            color,
            this.ctx,
            this.obstacles,
            this.sinks,
            (index) => {
                this.balls = this.balls.filter(ball => ball !== newBall);
                this.drawSinks(index)
                this.onFinish?.(index, startX, this.balls.length)
            },
            this.onObstaclesCollision
        )
        this.balls.push(newBall)
    }

    drawObstacles() {
        this.ctx.fillStyle = 'white';
        this.obstacles.forEach((obstacle) => {
            this.ctx.beginPath();
            this.ctx.arc(unpad(obstacle.x), unpad(obstacle.y), obstacle.radius, 0, Math.PI * 2);
            this.ctx.fill();
            this.ctx.closePath();
        });
    }

    getSinkColor(index: number): string {
        const middleSinkIndex = this.sinks.length % 2 !== 0
            ? [Math.ceil(this.sinks.length / 2 - 1)]
            : [this.sinks.length / 2 - 1, this.sinks.length / 2]

        if (index < middleSinkIndex[0]) {
            return sinkColor[this.risk][middleSinkIndex[0] - index]
        } else if (index > middleSinkIndex[middleSinkIndex.length - 1]) {
            return sinkColor[this.risk][index - middleSinkIndex[middleSinkIndex.length - 1]]
        } else {
            return sinkColor[this.risk][0]
        }
    }

    drawSinks(index?: number) {
        this.ctx.fillStyle = 'green';
        const SPACING = obstacleRadius * 2;

        const drawSink = (index: number, yOffset = 0) => {
            const sink = this.sinks[index]

            this.ctx.fillStyle = this.getSinkColor(index)

            // shadow
            this.ctx.globalAlpha = 0.5
            drawRoundedRect(this.ctx, sink.x, sink.y - sink.height / 2 + 4, sink.width - SPACING, sink.height, 5)

            this.ctx.globalAlpha = 1
            drawRoundedRect(this.ctx, sink.x, sink.y - sink.height / 2 + yOffset, sink.width - SPACING, sink.height, 5)

            this.ctx.font = 'medium 10px Inter'
            this.ctx.textBaseline = 'middle'
            this.ctx.textAlign = 'center'
            this.ctx.fillStyle = '#000';
            this.ctx.fillText((sink?.multiplier)?.toString() + "x", sink.x + sink.width / 2 - 4, sink.y + yOffset, sink.width);
        }

        if (index) {
            drawSink(index, 4)
            return
        }

        for (let i = 0; i < this.sinks.length; i++)  {
            drawSink(i)
        }
    }

    draw() {
        this.ctx.clearRect(0, 0, this.width, this.height);
        this.drawObstacles();
        this.drawSinks();
        this.balls.forEach(ball => {
            ball.draw();
            ball.update();
        });
    }
    
    update() {
        this.draw();
        this.requestId = requestAnimationFrame(this.update.bind(this));
    }

    stop() {
        if (this.requestId) {
            cancelAnimationFrame(this.requestId);
        }
    }
}